<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
	<div id="app" :class="vueAppClasses">
		<router-view @setAppClasses="setAppClasses" />
		<vue-progress-bar></vue-progress-bar>
	</div>
</template>

<script>
import themeConfig from '@/../themeConfig.js'
import mapboxgl from "mapbox-gl";
export default {
  data() {
    return {
      vueAppClasses: [],
    }
  },
  watch: {
    '$store.state.theme'(val) {
      this.toggleClassInBody(val)
    },
    '$vs.rtl'(val) {
      document.documentElement.setAttribute("dir", val ? "rtl" : "ltr")
    },
},
  methods: {
      toggleClassInBody(className) {
          if (className == 'dark') {
              if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
              document.body.classList.add('theme-dark')
          } else if (className == 'semi-dark') {
              if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
              document.body.classList.add('theme-semi-dark')
          } else {
              if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
              if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
          }
      },
      setAppClasses(classesStr) {
          this.vueAppClasses.push(classesStr)
      },
      handleWindowResize() {
          this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

          // Set --vh property
          document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
      },
      handleScroll() {
          this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
      },
  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme)
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    mapboxgl.setRTLTextPlugin(
      'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
      null,
      true
    );
  },
  async created() {

    let dir = this.$vs.rtl ? "rtl" : "ltr"
    document.documentElement.setAttribute("dir", dir)

    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)

  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
}

</script>

<style>
  * {
    font-family: 'Cairo', sans-serif;
  }
  /* cairo-regular - latin_arabic */
  @font-face {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('../src/assets/fonts/cairo-font/cairo-v9-latin_arabic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../src/assets/fonts/cairo-font/cairo-v9-latin_arabic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
</style>
