<template>
    <div>
        <vs-card>
            <vs-row vs-type="flex" vs-justify="space-between">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                    <h5>
                        <vs-chip color="primary">المعرف : {{ this.$route.params.id }}</vs-chip>
                    </h5>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                    <h5>
                        <vs-chip color="primary"> الحجم: {{ packageSize }}</vs-chip>
                    </h5>
                </vs-col>
            </vs-row>
        </vs-card>
        <ag-table :gridOptions="gridOptions"
                  :rowData="rowData"
                  @emitAddNewRecord="createRecord()"
                  @emitDeleteMultipleRecords="deletePackageClass($event, null , true)"
        >
        </ag-table>
        <vs-popup :title="dialogTitle" :active.sync="popupActive">
            <vs-progress v-if="isLoading" indeterminate color="primary"></vs-progress>
            <ValidationObserver v-slot="{handleSubmit}" ref="validationObserver">
                <form>
                    <div class="vx-row justify-center">
                        <div class="vx-col w-full">
                            <ValidationProvider name="فئة التغليف" rules="required" v-slot="{ errors }">
                                <label for="">فئة التغليف</label>
                                <vs-input placeholder="فئة التغليف" name="class"
                                          v-model="packageClass.class"
                                          class="w-full"
                                ></vs-input>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="vx-row justify-center mt-5">
                        <div class="vx-col w-full">
                            <ValidationProvider name="سعر فئة التغليف" rules="required" v-slot="{ errors }">
                                <label for="">سعر فئة التغليف</label>
                                <vs-input type="number" placeholder="السعر" name="price"
                                          v-model="packageClass.price"
                                          class="w-full"
                                ></vs-input>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <vs-col vs-type="flex" vs-justify="start" vs-align="right" vs-w="12">
                        <vs-row class="mt-5">
                            <vs-card v-for="(image ,index) in packageClass.images" v-bind:key="image.id">
                                <div class="vx-row">
                                    <div class="vx-col  w-full text-center">
                                        <h4 class="my-5">الصورة</h4>
                                        <image-uploader @fileUploaded="fileUploaded"
                                                        :imagePreview="imagePreview[index]"
                                                        :isImageRequired="isImageRequired"
                                                        :index="index"
                                                        ref="imageUploader"/>
                                    </div>
                                </div>
                                <vs-row  v-if="!isFirstClass" class="mt-5">
                                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-sm="4" vs-xs="12">
                                        <vs-button color="danger" type="gradient" icon-pack="feather" icon="icon-x"
                                                   @click="deleteImage(index)"/>
                                    </vs-col>
                                </vs-row>
                            </vs-card>
                        </vs-row>
                    </vs-col>
                    <vs-col vs-lg="12" class="mb-6">
                        <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-plus" size="small"
                                   @click="addImage">اضافة صورة
                        </vs-button>
                    </vs-col>
                    <div class="vx-row mt-5">
                        <div class="vx-col sm:w-1/2 w-full mb-2">
                            <vs-button :disabled="isLoading" ycolor="success" type="filled" @click.prevent="handleSubmit(onSubmit)">
                                حفظ
                            </vs-button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </vs-popup>
        <vs-popup title="تفاصيل فئة التغليف" :active.sync="popupActiveDetails">
            <form>
                <div v-for="imageClass in packageClass.images" v-bind:key="imageClass.id">
                <div class="vx-row mt-5">
                    <img v-if="imageClass" :src="imageClass" class="m-auto custom-image-size">
                </div>
                </div>
                <div class="vx-row mt-10">
                    <div class="vx-col sm:w-1/4 w-full">
                        <h4>المعرّف : </h4>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full mb-2">
                        <h4>{{ packageClass.id }}</h4>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full">
                        <h4>حجم التغليف : </h4>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full mb-2">
                        <h4>{{ packageSize }}</h4>
                    </div>
                </div>
                <div class="vx-row mt-10">
                    <div class="vx-col sm:w-1/4 w-full">
                        <h4>فئة التغليف : </h4>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full mb-2">
                        <h4>{{ packageClass.class }}</h4>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full">
                        <h4>سعر التغليف : </h4>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full mb-2">
                        <h4>{{ packageClass.price }}</h4>
                    </div>
                </div>
                <div class="vx-row mt-5">
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                        <vs-button ycolor="success" type="filled" @click="popupActiveDetails=false">حسناً
                        </vs-button>
                    </div>
                </div>

            </form>

        </vs-popup>
    </div>
</template>

<script>
    import AgTable from '../../../shared/shared-components/ag-grid/AgTable';
    import Loader from "@/app/shared/shared-components/Loader";
    import AgTableImageCell from '../../../shared/shared-components/ag-grid/AgTableImageCell'
    import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell";
    import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
    import vSelect from 'vue-select'
    import imageUploader from '../../../shared/shared-components/ImageUploader'
    import utilities from '../../../shared/utilities/index'
    import 'quill/dist/quill.snow.css'
    import {quillEditor} from 'vue-quill-editor'

    const packageClassRepo = RepositoryFactory.get('packageClass');
    const packageSizeRepo = RepositoryFactory.get('packageSize');

    export default {
        name: "PackageClass",
        components: {
            AgTable,
            Loader,
            vSelect,
            imageUploader,
            quillEditor
        },
        data() {
            return {
                isLoading: true,
                gridOptions: null,
                rowData: [],
                popupActiveDetails: false,
                popupActive: false,
                isImageRequired: true,
                imagePreview: [],
                dialogTitle:'',
                packageSize:'',
                isFirstClass: true,
                packageClass: {
                    id: null,
                    class: '',
                    price:'',
                    package_size_id:this.$route.params.id ,
                    images:['imageUrl']
                }

            }
        },
        methods: {
            createColDefs() {
                let self = this;
                return [
                    {
                        headerName: 'المعرف',
                        field: 'id',
                        filter: 'agTextColumnFilter',
                        suppressSizeToFit: true,
                        imagePreview: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                    },
                    {
                        headerName: 'الفئة',
                        field: 'class',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'السعر',
                        field: 'price',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'الصورة',
                        field: 'images.0',
                        cellRendererFramework: AgTableImageCell,
                    },
                    {
                        headerName: 'الإجراءات',
                        field: 'id',
                        cellRendererFramework: AgTableBtnCell,
                        cellRendererParams: {
                            deleteRecord: function (id,rowIndex) {
                                self.deletePackageClass(id,rowIndex)
                            },
                            viewRecordDetails: function (record) {
                                self.viewRecordDetails(record)
                            },
                            editRecord: function (packageClass) {
                                self.editRecord(packageClass)
                            },
                            actions: ['edit','delete','view']
                        },

                    },
                ];
            },
            rowClicked(id) {
                this.$router.push(`/ecommerce/products/${id}/sub_products`)
            },
            viewRecordDetails(record) {
                this.popupActiveDetails = true;
                this.packageClass = record;
            },
            fileUploaded(file,index) {
                if(file!== null) {
                    this.isLoading = true;
                    packageSizeRepo.uploadImage(this.$objectToFD({file: file})).then((response) => {
                        this.packageClass.images[index] = response.data;
                        this.isLoading = false;
                    });
                }
            },
            // used to rerender updated row
            rerender() {
                this.gridApi.setRowData(this.rowData)
            },
            openPopup() {
                this.$refs.validationObserver.reset();
                this.popupActive = true;
            },
            // used to fire the dialog which response to add a new record in the table
            createRecord() {
                utilities.initObjectMembers(this.packageClass);
                this.packageClass.images=['imageUrl'];
                this.packageClass.package_size_id = this.$route.params.id;
                for(let i=0;i<this.$refs.imageUploader.length;i++)
                    this.$refs.imageUploader[i].clearImage();
                this.imagePreview = [];
                this.dialogTitle = 'اضافة فئة تغليف لهذا الحجم';
                this.openPopup();
            },
            addImage() {
                this.isFirstClass = false;
                this.packageClass.images.push('imageUrl');
            },
            deleteImage(index) {
                this.packageClass.images.splice(index, 1)
            },
            // used to fire the dialog which response to edit current record in the table
            editRecord(packageClass) {
                for(let i=0;i<this.$refs.imageUploader.length;i++)
                    this.$refs.imageUploader[i].clearImage();
                this.dialogTitle = 'تعديل فئة التغليف';
                Object.assign(this.packageClass, packageClass);
                for(let i=0;i< this.packageClass.images.length;i++)
                    this.imagePreview[i] = this.packageClass.images[i];
                this.openPopup();
            },
            fileUpload(e,index){
                this.isLoading = true;
                packageSizeRepo.uploadImage(this.$objectToFD({file: e.target.files[0]})).then((response) => {
                    this.packageClass.images[index]=response.data;
                    this.isLoading = false;
                })
            },
            onSubmit() {
                this.isLoading = true;
                if (typeof this.packageClass.logo_url == "string")
                    delete this.packageClass.logo_url;
                if (this.packageClass.id !== null) {
                    this.updatePackageClass(this.packageClass);
                } else {
                    this.storePackageClass(this.packageClass);
                }
            },

            storePackageClass(packageClass) {
                packageClassRepo.storePackageClass(this.$objectToFD(packageClass)).then((response) => {
                    this.rowData.unshift(response.data.data);
                    this.popupActive = false;
                    this.isLoading = false;
                });
            },
            updatePackageClass(packageClass) {
                packageClassRepo.updatePackageClass(this.$objectToFD(packageClass), packageClass.id).then((response) => {
                    let index = this.rowData.map((m) => {
                        return m.id;
                    }).indexOf(response.data.data.id);
                    this.rowData[index] = response.data.data;
                    this.rerender();
                    this.popupActive = false;
                    this.isLoading = false;
                });
            },
            deletePackageClass(ids, index, isMultiple = false) {
                this.isLoading = true;
                if (isMultiple)
                    ids = ids.join(',')
                packageClassRepo.deletePackageClass(ids).then((response) => {
                    if (response.code === 200) {
                        if (isMultiple)
                            this.fetchPackageClassesById();
                        else {
                            this.rowData.splice(index, 1);
                            this.rerender();
                        }
                    }
                    this.isLoading = false;
                })
            },
            fetchPackageClassesById(){
                packageClassRepo.fetchPackageClassesById(this.$route.params.id).then((data) => {
                    this.rowData = data.package_classes;
                    this.packageSize = data.size;
                    this.isLoading = false;
                });
            },
        },
        created() {
            // prepare ag grid options
            this.gridOptions = {};
            this.gridOptions.columnDefs = this.createColDefs();
            this.fetchPackageClassesById(this.$route.params.id);

        },
        mounted() {
            this.gridApi = this.gridOptions.api;
        }
    }
</script>

<style scoped>

</style>
