<template>
  <tm-popup :submit="onSubmit"
            :submit-button-disabled="submitButtonDisabled"
            title="تفاصيل المنتج الفرعي"
            ref="popup"
            :fullscreen="true">
    <vs-row>
      <vs-col vs-type="flex" vs-justify="start" vs-align="right" vs-w="12">
        <vs-row>
          <vs-card v-for="(subProduct,index) in subProducts" v-bind:key="subProduct.id">
            <div class="vx-row mi-format-align-justify">
              <div class="vx-col sm:w-1/2 w-full">
                <tm-select
                  class="w-full"
                  :options="products"
                  label="shownName"
                  validation-rules="required"
                  name="المنتج"
                  v-model="product_id"
                  :reduce="name => name.id"
                />
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <tm-input
                  name="الاسم"
                  type="text"
                  placeholder="الاسم"
                  validation-rules="required"
                  v-model="subProduct.name"
                  class="w-full"/>
              </div>
            </div>
            <div class="vx-row mi-format-align-justify">
              <div class="vx-col sm:w-1/2 w-full">
                <tm-input
                  name="السعر"
                  type="number"
                  placeholder="السعر"
                  validation-rules="required"
                  v-model="subProduct.price"
                  class="w-full"/>
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <tm-input
                  name="الكمية"
                  type="number"
                  placeholder="الكمية"
                  validation-rules="required|min_value:0"
                  v-model="subProduct.qty"
                  class="w-full"/>
              </div>
            </div>
            <div class="vx-row mi-format-align-justify">
              <div class="vx-col sm:w-1/2 w-full">
                <tm-input
                  name="وقت تجهيز المنتج"
                  type="number"
                  placeholder="وقت تجهيز المنتج"
                  validation-rules="required|min_value:1"
                  v-model="subProduct.process_time"
                  class="w-full"/>
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <tm-select
                  class="w-full"
                  :options="packageSizes"
                  label="shownName"
                  name="حجم التغليف"
                  v-model="subProduct.package_size_id"
                  :reduce="size => size.id"
                />
              </div>
            </div>
            <div class="vx-row mi-format-align-justify">
              <div class="vx-col sm:w-1/2 w-full">
                <tm-input
                  name="التفاصيل"
                  type="text"
                  placeholder="التفاصيل"
                  v-model="subProduct.desc"
                  class="w-full"/>
              </div>

              <div class="vx-col sm:w-1/2 w-full">
                <tm-select
                  :options="['OLD','NEW']"
                  validation-rules="required"
                  name="الحالة"
                  v-model="subProduct.stock_status"
                  class="w-full mb-6"
                />
              </div>
            </div>
            <div class="vx-row mi-format-align-justify">
              <div class="vx-col w-full">
                <tm-select
                  name="التاغ (اختر تاغ واحد أو أكثر)"
                  :options="tags"
                  :multiple="true"
                  validation-rules="required"
                  v-model="subProduct.tags"
                  label="name"
                  :reduce="name => name.id"
                />
              </div>
            </div>

            <div class="vx-row mi-format-align-justify mb-5">
              <div class="vx-col sm:w-1/2 w-full">
                <tm-select
                  name="اسم الخاصية"
                  :options="variants"
                  validation-rules="required"
                  label="name"
                  v-model="variantKey"
                  @input="selectVariantKey"
                />
              </div>
              <div class="vx-col sm:w-1/2 w-full mb-3">
                <tm-select
                  name="قيمة الخاصية"
                  :options="variantValues"
                  validation-rules="required"
                  label="value"
                  v-model="variantValue"
                />
              </div>
            </div>
            <vs-row class="mb-5">
              <div v-for="(variant,i) in subProduct.variants" v-bind:key="i">
                <vs-chip color="primary">
                  {{variant.variant_key}}
                </vs-chip>
                <vs-chip transparent color="primary" closable @click="deleteSubproductVariants(index,i)">
                  {{variant.variant_value}}
                </vs-chip>
              </div>
            </vs-row>
            <vs-row>
              <vs-col
                vs-align="right"
                vs-justify="start"
                vs-lg="6"
                vs-sm="4"
                vs-type="flex"
                vs-xs="12">
                <ValidationProvider
                  name="إضافة خاصية"
                  rules="required"
                  v-slot="{ errors }">
                  <input
                    hidden
                    v-model="subProduct.isVariantsExist">
                  <vs-button
                    @click="addVariantValue(index)"
                    color="primary"
                    icon="icon-plus"
                    icon-pack="feather"
                    size="small"
                    type="filled">اضافة الخاصية
                  </vs-button>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </vs-col>
            </vs-row>
            <div class="vx-row mi-format-align-justify mb-5">
              <div class="vx-col w-full">
                <h4 class="my-5">الصور</h4>
                <file-pond-component
                  :files="subProduct.images.map(image => image.image_url)"
                  :multiple="true"
                  @fileDeleted="(fileIndex) => fileDeleted(index, fileIndex)"
                  @fileUploaded="(files) => fileUploaded(files, index)"
                  project="website"
                />
              </div>
            </div>
            <vs-col vs-type="flex" vs-justify="start" vs-align="right" vs-w="12">
              <vs-row>
                <vs-card v-for="(keyValue, x) in subProduct.details" v-bind:key="keyValue.id">
                  <vs-row vs-align="center">

                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="12">
                      <tm-input
                        name="اسم القيمة"
                        type="text"
                        placeholder="اسم القيمة"
                        validation-rules="required"
                        v-model="keyValue.key"/>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="4" vs-xs="12">
                      <tm-input
                        name="التفاصيل"
                        type="text"
                        placeholder="التفاصيل"
                        validation-rules="required"
                        v-model="keyValue.value"
                        class="w-full"/>
                    </vs-col>
                    <vs-col v-if="isManyKeyValue" vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12">
                      <vs-button color="danger" type="gradient" icon-pack="feather" icon="icon-x"
                                 @click="deleteKeyValue(index,x)"/>
                    </vs-col>
                  </vs-row>
                </vs-card>
                <vs-col vs-lg="12" class="mb-6">
                  <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-plus" size="small"
                             @click="addKeyValue(index)">اضافة
                  </vs-button>
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col v-if="isManySubProduct" vs-type="flex" vs-justify="end" vs-align="left" vs-lg="12" vs-sm="4"
                    vs-xs="12">
              <vs-button color="danger" type="gradient" icon-pack="feather" icon="icon-x" size="small"
                         @click="deleteSubproduct(index)">حذف المنتج الفرعي
              </vs-button>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="start" vs-align="right" vs-w="12" class="mb-5">
              <vs-checkbox class="inline-flex" v-model="subProduct.allow_upload_image">السماح برفع صورة</vs-checkbox>
            </vs-col>
          </vs-card>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="start" vs-align="right" vs-lg="6" vs-sm="4" vs-xs="12">
              <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-plus" size="small"
                         @click="addSubproduct">اضافة منتج فرعي
              </vs-button>
            </vs-col>
          </vs-row>
        </vs-row>
      </vs-col>
    </vs-row>
  </tm-popup>
</template>

<script>
  import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
  import TmPopup from "../../../shared/shared-components/TmPopup";
  import TmInput from "../../../shared/shared-components/TmInput";
  import TmSelect from "../../../shared/shared-components/TmSelect";
  import TmEditor from "../../../shared/shared-components/TmEditor";
  import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
  import FilePondComponent from "@/app/shared/shared-components/FilePondComponent";

  const subProductRepository = RepositoryFactory.get("subProduct");
  const productRepository = RepositoryFactory.get("product");
  const tagRepository = SharedRepositoryFactory.get("tag");
  const variantsRepo = RepositoryFactory.get('variantKey');
  const packageSizeRepo = RepositoryFactory.get("packageSize");

  export default {
    name: "StorePopup",
    data() {
      return {
        products: [{id: '', name: '', shownName: ''}],
        product_id: parseInt(this.productId),
        packageSizes:[],
        isManySubProduct: false,
        isManyKeyValue: false,
        tags: [],
        subProductsTags: [],
        variants: [],
        variantValue: null,
        disabled: false,
        variantKey: null,
        variantValues: [],
        imagePreview: null,
        isFirstImage: true,
        submitButtonDisabled: false
      }
    },

    props: {
      subProducts: {
        type: Array,
        required: true
      },
      productId: {
        type: String,
        required: true
      }
    },

    methods: {
      onSubmit() {
        this.$refs.popup.loading();
        this.create();
      },
      fileUploaded(files, subProductIndex) {
        this.submitButtonDisabled = false;
        this.subProducts[subProductIndex].images.push(...files.map(file => ({image_url: file})));
      },
      fileDeleted(subProductIndex, imageIndex) {
        this.subProducts[subProductIndex].images.splice(imageIndex, 1);
      },
      create() {
        this.subProducts.forEach(item => {
          item['product_id'] = this.product_id;
          for (let i = 0; i < item.tags.length; i++) {
            this.subProductsTags.push({tag_id: item.tags[i]})
          }
          item.tags = this.subProductsTags;
          this.subProductsTags = [];
        });

        subProductRepository.store(this.$objectToFD({subProducts: this.subProducts})).then((response) => {
          this.$refs.popup.close();
          this.$emit("created", response.data);
        });
      },
      open() {
        this.$refs.popup.open();
      },
      getAllProducts() {
        productRepository.getAll().then(response => {
          this.products = response.data;
          this.products.map(p => {
            p.shownName = `${p.category.store.name} - ${p.name}`;
            return p;
          });
        });
      },
      getAllPackageSizes() {
        packageSizeRepo.fetchAllPackageSizes().then((data) => {
          this.packageSizes = data;
          this.packageSizes.map(p => {
            p.shownName = `${p.size}`;
            return p;
          });
        });
      },
      fetchAllTags() {
        tagRepository.fetchAllTags().then((response) => {
          this.tags = response;
        })
      },
      fetchAllVariants() {
        variantsRepo.getAllVariant().then((response) => {
          this.variants = response
        })
      },
      addSubproduct() {
        this.isManySubProduct = true;
        this.subProducts.push(this.lodash.cloneDeep(this.subProducts[0]))
      },
      deleteSubproduct(index) {
        this.subProducts.splice(index, 1)
      },
      deleteSubproductVariants(index, variantValueIndex) {
        this.subProducts[index].variants.splice(variantValueIndex, 1);
        if (this.subProducts[index].variants.length === 0)
          this.subProducts[index].isVariantsExist = '';
      },
      deleteKeyValue(index,keyValueIndex) {
        this.subProducts[index].details.splice(keyValueIndex, 1)
      },
      selectVariantKey(selectedValue) {
        this.variantValue = null;
        this.variantValues = selectedValue.variantValues
      },
      addVariantValue(index) {
        this.subProducts[index].isVariantsExist = true;
        this.subProducts[index].variants.push(
          {
            variant_value_id: this.variantValue.id,
            variant_value: this.variantValue.value,
            variant_key: this.variantValue.key
          });
      },
      addKeyValue(index) {
        this.isManyKeyValue = true;
        this.subProducts[index].details.push({
            key: null,
            value: null
          }
        )
      },
    },
    created() {
      this.getAllProducts();
      this.fetchAllTags();
      this.fetchAllVariants();
      this.getAllPackageSizes();
    },
    components: {
      TmPopup,
      TmInput,
      TmSelect,
      TmEditor,
      FilePondComponent
    },
  }
</script>
