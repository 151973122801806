import Vue from 'vue'
import Router from 'vue-router'
import ourRoutes from './app/app-routes'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '/',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('./views/Home.vue'),
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '/error',
      // component: () => import('@/layouts/full-page/FullPage.vue'),
      name: 'error-404',
      component: () => import('@/views/pages/Error404.vue')
    },
    ...ourRoutes,
    // Redirect to 404 page, if no match found
    // {
    //     path: '*',
    //     redirect: '/pages/error-404'
    // },
  ],
});

// check auth status
router.beforeEach((to, from, next) => {
  // get user object beacuse vuex-presistedstae library doesnt active in the first
  const loggedIn = localStorage.getItem('user');
  if (to.matched.some(record => record.meta.auth === false)) {
    next();
    return;
  }

  if (!loggedIn && to.name !== "login") {
    next({name: 'login'})
    return;
  } else if (loggedIn && to.name === "login") {
    next({name: 'home'})
    return;
  }
  next()
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})


export default router
