import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.ecommerceApiBaseUrl}category`;
export default {
  getAll() {
    return httpClient.get(URL)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  fetchAllCategories() {
      return httpClient.get(URL)
          .then(res => res.data['data'])
          .catch(error => console.log(error));

  },
  storeCategory(category) {
    return httpClient.post(URL, category)
      .then(res => res)
      .catch(error => console.log(error))
  },
  updateCategory(category, id) {
    const url = URL + `/${id}?_method=PUT`;
    return httpClient.post(url, category)
      .then(res => res)
      .catch(error => console.log(error))
  },
  deleteCategory(categoryId, isImage = false) {
    let url = URL + '/' + categoryId;
    url = isImage ? url + '/images' : url;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error))
  },
  /*hiddenCategory(id) {
    const url = environment.ecommerceApiBaseUrl + `toggle-hide/category/${id}`;
    return httpClient.post(url)
        .then(res => res.data.data)
        .catch(error => console.log(error));
  }*/
}
