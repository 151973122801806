import {httpClient} from "../shared-services/httpClient";
import {environment} from "@/environment";
const URL = environment.apiBaseURL + 'group-member';
export default {

    fetchAllMembers(groupId) {
        const url = environment.apiBaseURL + `group/${groupId}/members`;
        return httpClient.get(url)
            .then(res => res.data.data)
            .catch(error => console.log(error))
    },
    storeMember(member) {
        return httpClient.post(URL, member)
            .then(res => res)
            .catch(error => console.log(error))
    },
    updateMember(member) {
        const url = URL + `/${member.id}?_method=PUT`;
        return httpClient.post(url, member)
            .then(res => res)
            .catch(error => console.log(error))
    },
    deleteMember(memberId){
        const url = URL +`/${memberId}`;
        return httpClient.delete(url)
            .then(res => res["data"])
            .catch(error => console.log(error))
    }
}