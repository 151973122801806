import {httpClient} from "../shared-services/httpClient";
import {environment} from "@/environment";
const URL = environment.apiBaseURL + 'user/notifications';
export default {

    fetchAllNotification() {
        return httpClient.get(URL)
            .then(res => res.data.data)
            .catch(error => console.log(error))
    },
    deleteNotification(notificationId) {
        let url = URL + '/delete/' + notificationId;
        return httpClient.delete(url)
            .then(res => res.data)
            .catch(error => console.log(error))
    },
    deleteAllNotification() {
        let url = URL + '/delete-read';
        return httpClient.delete(url)
            .then(res => res.data)
            .catch(error => console.log(error))
    },
    readNotification(notificationId) {
        let url = environment.apiBaseURL +'user/mark-notifications/read/' + notificationId;
        return httpClient.get(url)
            .then(res => res.data)
            .catch(error => console.log(error))
    },
    readAllNotification() {
        let url = environment.apiBaseURL +'user/mark-notifications/read';
        return httpClient.get(url)
            .then(res => res.data)
            .catch(error => console.log(error))
    }
}