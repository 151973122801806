<template>
  <div>
    <ag-table :gridOptions="gridOptions"
              :rowData="rowData"
              @emitAddNewRecord="createRecord()"
              @emitDeleteMultipleRecords="deleteCategory($event, null , true)"
    >
    </ag-table>
    <vs-popup title="تفاصيل صنف المنتج" :active.sync="popupActive">
      <vs-progress v-if="isLoading" indeterminate color="primary"></vs-progress>
      <ValidationObserver v-slot="{handleSubmit}" ref="validationObserver">
        <form>
          <!-- Logo -->
          <div class="vx-row">
            <div class="vx-col  w-full text-center">
              <h4 class="my-5">الشعار</h4>
              <image-uploader @fileUploaded="fileUploaded"
                              :imagePreview="imagePreview"
                              :isImageRequired="imageRequired"
                              ref="imageUploader"/>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full">
              <ValidationProvider name="الاسم" rules="required" v-slot="{ errors }">
                <label for="">الاسم</label>
                <vs-input placeholder="الاسم" name="name"
                          v-model="category.name"
                          class="w-full"
                ></vs-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="vx-col sm:w-1/2 w-full mb-2">
              <label for="">النوع المشتق منه</label>
              <ValidationProvider name="المحال" v-slot="{ errors }">
                <v-select label="name" :options="categories"
                          @input="selectedParentCategory"
                          v-model="category.category_parent"
                          :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label for="">المحل</label>
              <ValidationProvider name="المحل" :rules="'required'" v-slot="{ errors }">
                <v-select label="name" :options="stores"
                          @input="selectedStore"
                          v-model="category.store"
                          :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button :disabled="isLoading" ycolor="success" type="filled" @click.prevent="handleSubmit(onSubmit)">
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>

    <vs-popup title="تفاصيل صنف المنتج" :active.sync="popupActiveDetails">
      <form>
        <div class="vx-row">
          <img :src="category.cover_url" class="m-auto custom-image-size">
        </div>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>المعرّف : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ category.id }}</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <h4>الاسم : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ category.name }}</h4>
          </div>
        </div>

        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>المحل : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ category.store_id }}</h4>
          </div>

          <div class="vx-col sm:w-1/4 w-full">
            <h4>عدد المنتجات : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ category.sub_product_count }}</h4>
          </div>
        </div>

        <div class="vx-row mt-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-button ycolor="success" type="filled" @click="popupActiveDetails=false">حسناً
            </vs-button>
          </div>
        </div>

      </form>

    </vs-popup>

  </div>
</template>

<script>
import AgTable from '../../../shared/shared-components/ag-grid/AgTable';
import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell";
import AgTableImageCell from '../../../shared/shared-components/ag-grid/AgTableImageCell'
//import AgButton from "../../../shared/shared-components/ag-grid/AgButton";
import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
import vSelect from 'vue-select'
import imageUploader from '../../../shared/shared-components/ImageUploader'
import utilities from '../../../shared/utilities/index'
import 'quill/dist/quill.snow.css'
import {quillEditor} from 'vue-quill-editor'


const categoryRepo = RepositoryFactory.get('category');
const storeRepo = RepositoryFactory.get('store');

export default {
  name: "Category",
  components: {
    AgTable,
    vSelect,
    imageUploader,
    quillEditor
  },
  data() {
    return {
      isLoading: true,
      gridOptions: null,
      rowData: [],
      popupActiveDetails: false,
      popupActive: false,
      imageRequired: true,
      imagePreview: null,
      dialogTitle: String,
      categories: [],
      stores: [],
      category: {
        id: null,
        name: '',
        cover_url: '',
        store_id: '',
        sub_product_count: '',
        category_parent: null,
        category_parent_id: null,
        hidden:false
      }
    }
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          imagePreview: null,
          width: 225,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: 'الاسم',
          field: 'name',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'اسم المحل',
          field: 'store.name',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الشعار',
          field: 'cover_url',
          cellRendererFramework: AgTableImageCell
        },
        {
          headerName: 'عدد المنتجات',
          field: 'sub_product_count',
          filter: 'agTextColumnFilter'
        },
       /* {
          headerName: 'عرض أو إخفاءالصنف',
          cellRendererFramework: AgButton,
          field: 'hidden',
          valueGetter:
                  function getObject(params) {
                    return params
                  },
          filter: false,
          cellRendererParams : params => {
            return {
              size: 'small',
              color: 'primary',
              name: `${params.value.data.hidden ? 'عرض' : 'إخفاء'}`,
              click(valueGetter) {
                self.hiddenCategory(valueGetter.data.id)
              }
            }
          }
        },*/
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteCategory(id, rowIndex)
            },
            editRecord: function (category) {
              self.editRecord(category)
            },
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record)
            },
            actions: ['view', 'edit', 'delete']
          },

        },
      ];
    },
    deleteCategory(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',')
      categoryRepo.deleteCategory(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.fetchAllCategories();
          else {
            this.rowData.splice(index, 1);
            this.rerender();
          }
        }
        this.isLoading = false;
      })
    },
    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.category = record;
    },
   /* hiddenCategory(id){
      categoryRepo.hiddenCategory(id).then((category) => {
        let index = this.categories.findIndex(p => p.id === category.id);
        this.categories[index] = category;
        this.gridApi.setRowData(this.categories)
        this.isLoading = false;
      });
    },*/
    fileUploaded(file) {
      this.category.cover_url = file;
    },
    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData)
    },
    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },
    // used to fire the dialog which response to add a new record in the table
    createRecord() {
      this.categories = this.lodash.cloneDeep(this.rowData);
      utilities.initObjectMembers(this.category);
      this.$refs.imageUploader.clearImage();
      this.imagePreview = null;
      this.dialogTitle = 'اضافة صنف منتج';
      this.openPopup();
    },

    // used to fire the dialog which response to edit current record in the table
    editRecord(category) {
      this.categories = this.lodash.cloneDeep(this.rowData);
      let index = this.categories.map((m) => {
        return m.id;
      }).indexOf(category.id);
      this.categories.splice(index,1);
      this.$refs.imageUploader.clearImage();
      this.dialogTitle = 'تعديل صنف منتج';
      Object.assign(this.category, category);
      this.category.store_id = category.store.id;
      this.imagePreview = this.category.cover_url;
      this.openPopup();
    },
    selectedParentCategory(category) {
      this.category.category_parent_id = category.id;
    },
    selectedStore(store) {
      this.category.store_id = store.id;
    },
    onSubmit() {
      this.isLoading = true;
      if (typeof this.category.cover_url == "string")
        delete this.category.cover_url;
      if (this.category.id !== null) {
        this.updateCategory(this.category);
      } else {
        this.storeCategory(this.category);
      }
    },

    storeCategory(category) {
      categoryRepo.storeCategory(this.$objectToFD(category)).then((response) => {
        this.rowData.unshift(response.data.data);
        this.popupActive = false;
        this.isLoading = false;
      });
    },
    updateCategory(category) {
      categoryRepo.updateCategory(this.$objectToFD(category), category.id).then((response) => {
        let index = this.rowData.map((m) => {
          return m.id;
        }).indexOf(response.data.data.id);
        this.rowData[index] = response.data.data;
        this.rerender();
        this.popupActive = false;
        this.isLoading = false;
      });
    },
    fetchAllCategories() {
      categoryRepo.fetchAllCategories().then((data) => {
        this.rowData = data;
        this.isLoading = false;
      });
      storeRepo.fetchAllStores().then((data) => {
        this.stores = data;
      });
    },
  },
  created() {
    // prepare ag grid options
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllCategories();

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
}
</script>

<style scoped>

</style>
