<template>
    <tm-popup :submit="onSubmit" ref="popup" title="تفاصيل المنتج">
        <div class="vx-row mi-format-align-justify">
            <div class="vx-col sm:w-1/2 w-full">
                <tm-input
                        class="w-full"
                        name="الاسم"
                        placeholder="الاسم"
                        type="text"
                        v-model="product.name"
                        validation-rules="required"/>
            </div>

            <div class="vx-col sm:w-1/2 w-full">
                <tm-select
                        :options="categories"
                        :reduce="name => name.id"
                        label="name"
                        name="الصنف"
                        v-model="product.category.id"
                        validation-rules="required"
                />
            </div>
        </div>
    </tm-popup>
</template>

<script>
    import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
    import TmPopup from "../../../shared/shared-components/TmPopup";
    import TmInput from "../../../shared/shared-components/TmInput";
    import TmSelect from "../../../shared/shared-components/TmSelect";

    const productRepository = RepositoryFactory.get("product");
    const categoryRepository = RepositoryFactory.get("category");
    export default {
        name: "StorePopup",
        data() {
            return {
                categories: []
            }
        },

        props: {
            product: {
                type: Object,
                required: true
            },
        },

        methods: {
            onSubmit() {
                this.$refs.popup.loading();
                if (this.product.id == null)
                    this.create();
                else
                    this.update();
            },
            create() {
                productRepository.store(this.product).then((response) => {
                    this.$refs.popup.close();
                    this.$emit("created");
                });
            },
            update() {
                productRepository.update(this.product).then((response) => {
                    this.$refs.popup.close();
                    this.$emit("updated", response.data);
                });
            },
            open() {
                this.$refs.popup.open();
            },
        },
        components: {
            TmPopup,
            TmInput,
            TmSelect
        },

        created() {
            categoryRepository.getAll().then((response) => {
                this.categories = response.data
            })
        }
    }
</script>

<style scoped>

</style>
