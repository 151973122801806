import {ecommerceRoutes} from "./ecommerce/ecommerce-index";
import sharedRoutes from './shared/shared-routes'
import error from '../views/pages/Error404';
import notAuthorized from '../views/pages/NotAuthorized';


const appRoutes = [
  {
    path: '/test-route',
    name: 'Home',
    component: error
  },
  {
    path: '/not-authorized',
    name: 'NotAuthorized',
    component: notAuthorized
  }
];

const ourRoutes = [...sharedRoutes, ...appRoutes,...ecommerceRoutes];

export default ourRoutes
