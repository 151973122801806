<template>
  <ValidationProvider :name="name" :rules="validationRules" v-slot="{ errors }">
    <label for="">{{name}}</label>
    <quill-editor v-model="computedValue"/>
    <span class="text-danger">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
  import {quillEditor} from 'vue-quill-editor'

  export default {
    name: "TmEditor",
    props: {
      name: {type: String, required: true},
      value: {type: [Number, String]},
      validationRules: {type: String, required: true}
    },
    computed: {
      computedValue: {
        get() {
          return this.value
        },
        set(newValue) {
          this.$emit("input", newValue)
        }
      }
    },
    components: {
      quillEditor
    }
  }
</script>

<style scoped>

</style>
