<template>
    <div>
        <vs-card>
            <vs-row vs-type="flex" vs-justify="space-between">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                    <h5>
                        <vs-chip color="primary">المعرف : {{ user.id }}</vs-chip>
                    </h5>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                    <h5>
                        <vs-chip color="primary"> اسم المستخدم : {{ user.last_name }}</vs-chip>
                    </h5>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                    <h5>
                        <vs-chip color="primary"> الإيميل : {{ user.email }}</vs-chip>
                    </h5>
                </vs-col>
            </vs-row>
        </vs-card>
        <ag-table :gridOptions="gridOptions" :rowData="rowData"
                  @emitShowCurrentRecords="fetchAllRolesForUser(this.$route.params.id)"
                  @emitAddNewRecord="createRecord()"
                  @emitDeleteMultipleRecords="deleteRole($event, null , true)"
        />
        <vs-popup title="إضافة دور لهذا المستخدم" :active.sync="popupActive">
            <vs-progress v-if="isLoading" indeterminate color="primary"></vs-progress>
            <ValidationObserver v-slot="{handleSubmit}" ref="validationObserver">
                <form>
                    <div class="vx-row mt-5">
                        <div class="vx-col sm:w-1/4 w-full  mb-2">
                            <label>اختر الدور</label>
                        </div>
                        <div class="vx-col sm:w-1/2 w-full  mb-2">
                            <v-select label="name_ar" :options="roles"
                                      :value="role.name_ar"
                                      :multiple="true"
                                      v-model="selectedRoles"
                                      :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
                        </div>
                    </div>
                    <div class="vx-row mt-5">
                        <div class="vx-col sm:w-1/2 w-full mb-2">
                            <vs-button :disabled="isLoading" ycolor="success" type="filled" @click.prevent="handleSubmit(onSubmit())">
                                حفظ
                            </vs-button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </vs-popup>

    </div>
</template>

<script>

    import AgTable from '../shared-components/ag-grid/AgTable'
    import AgTableBtnCell from '../shared-components/ag-grid/AgTableBtnCell.vue'
    import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
    import vSelect from 'vue-select'
    import utilities from "@/app/shared/utilities";
    import Loader from "@/app/shared/shared-components/Loader";
    import AgButton from "@/app/shared/shared-components/ag-grid/AgButton";

    const roleRepo =  SharedRepositoryFactory.get('roleRepository');
    const userRepo = SharedRepositoryFactory.get('userRepository');

    export default {
        name: "UserMain",
        components: {
            AgTable,
            vSelect,
            Loader,
        },

        data() {
            return {
                gridOptions: null,
                gridApi: null,
                rowData: [],
                isLoading: true,
                popupActive: false,
                user:Object,
                roles :[],
                selectedRoles:[],
                role :{
                    id : null,
                    name: '',
                    name_ar :'',
                    desc :''

                },
                rolesIds:[]
            }
        },

        methods: {
            createColDefs() {
                let self = this;
                return [
                    {
                        headerName: 'المعرف',
                        field: 'id',
                        filter: 'agTextColumnFilter',
                        suppressSizeToFit: true,
                        width: 225,
                        headerCheckboxSelection : true,
                        checkboxSelection: true,
                    },
                    {
                        headerName: 'الاسم',
                        field: 'name',
                        filter: 'agTextColumnFilter'

                    },
                    {
                        headerName: 'الاسم بالعربية',
                        field: 'name_ar',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'الوصف',
                        field: 'desc',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'الصلاحيات',
                        cellRendererFramework: AgButton,
                        field: 'id',
                        filter: false,
                        cellRendererParams: {
                            size: 'small',
                            color: 'primary',
                            name: 'عرض الصلاحيات',
                            type:'gradient',
                            click(id) {
                                self.rowClicked(id)
                            }
                        }
                    },
                    {
                        headerName: 'الإجراءات',
                        field: 'id',
                        cellRendererFramework: AgTableBtnCell,
                        cellRendererParams: {
                            deleteRecord: function (id,rowIndex) {
                                self.deleteRole(id,rowIndex)
                            },
                            actions: ['delete']
                        },

                    },
                ];
            },
            rowClicked(id) {
                this.$router.push(`/roles/${id}/permissions`)
            },
            createRecord() {
                utilities.initObjectMembers(this.role);
                this.openPopup();
            },
            openPopup() {
                this.fetchAllRoles();
                this.$refs.validationObserver.reset();
                this.popupActive = true;
            },
            onSubmit() {
                this.selectedRoles.forEach(item => {
                    this.rolesIds.push(item.id)
                });
                roleRepo.assignRoleToUser(this.rolesIds,this.$route.params.id).then((response) =>{
                    this.fetchAllRolesForUser(this.$route.params.id);
                    this.handleResponse(response.data);
                    this.isLoading = false;
                })
            },
            handleResponse() {
                this.popupActive = false;
            },
            deleteRole(ids, index, isMultiple = false) {
                this.isLoading = true;
                if(isMultiple)
                    ids = ids.join(',')
                roleRepo.removeRoleFromUser(ids,this.$route.params.id).then((response) => {
                    if (response.code === 200) {
                        if(isMultiple) {
                            this.fetchAllRolesForUser(this.$route.params.id);
                        }
                        else {
                            this.rowData.splice(index, 1);
                            this.rowData = [...this.rowData];
                        }
                    }
                    this.isLoading = false;
                })
            },
            fetchAllRoles(){
                roleRepo.fetchAllRoles().then((data) => {
                    this.roles = data;
                    this.isLoading = false;
                });
            },
            fetchAllRolesForUser(userId) {
                userRepo.fetchUserById(userId).then((response) => {
                    this.rowData = response.roles;
                    this.user = response;
                    this.isLoading = false;
                });
            }
        },

        created() {
            this.gridOptions = {};
            this.gridOptions.columnDefs = this.createColDefs();
            this.fetchAllRolesForUser(this.$route.params.id);
        },

        mounted() {
            this.gridApi = this.gridOptions.api;
        }
    }
</script>
