import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.ecommerceApiBaseUrl}package-size`;

export default {

    fetchAllPackageSizes() {
        return httpClient.get(URL)
            .then(res => res.data['data'])
            .catch(error => console.log(error))
    },
    fetchPackageSizeById(packageSizeId) {
        let url = URL + `/${packageSizeId}`;
        return httpClient.get(url)
            .then(res => res.data['data'])
            .catch(error => console.log(error))
    },
    storePackageSize(packageSize) {
        return httpClient.post(URL, packageSize)
            .then(res => res)
            .catch(error => console.log(error))
    },
    updatePackageSize(packageSize, id) {
        let url = URL + `/${id}?_method=PUT`;
        return httpClient.post(url, packageSize)
            .then(res => res)
            .catch(error => console.log(error))
    },
    deletePackageSize(packageSizeId) {
        let url = URL + '/' + packageSizeId;
        return httpClient.delete(url)
            .then(res => res["data"])
            .catch(error => console.log(error))

    },
    uploadImage(file){
        const url = `${environment.apiBaseURL}files`;
        return httpClient.post(url, file)
            .then(res => res.data)
            .catch(error => console.log(error))
    }
}
