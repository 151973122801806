import {httpClient} from "../shared-services/httpClient";
import {environment} from "@/environment";
const url = environment.apiBaseURL + 'configuration';
export default {

    fetchAllConfig(module) {
       return  httpClient.get(url)
                .then((res) => {
                    if (module === "Donate")
                        res = res.data.data.Donate;
                    else if (module === "Ecommerce")
                        res = res.data.data.Ecommerce;
                    else if (module === "Website")
                        res = res.data.data.Basic;
                    return res;
                })
    .catch(error => console.log(error))
    },
       updateConfig(config) {
            config = {
                id: config.id,
                value: config.value,
                _method :"PUT"
            };
            return httpClient.post(`${url}/${config.id}`, config)
                .then(res => res.data)
                .catch(error => console.log(error));
        },
}
