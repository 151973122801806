<template>
  <div>
    <p> welcome ecommerce home </p>
  </div>
</template>

<script>
  export default {
    name: "Home"
  }
</script>

<style scoped>

</style>
