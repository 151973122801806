<template>
    <div>
        <order-status :isShowAllStatus="true"></order-status>
        <ag-table :gridOptions="gridOptions"
                  :rowData="rowData"
                  :rtl=false
                  @emitShowCurrentRecords="fetchAllOrders('orphan_gift_orders')"
                  @emitShowCompletedRecords="fetchAllOrders('orphan_gift_orders',true)"
        >
        </ag-table>

        <vs-popup title="تفاصيل الطلب" :active.sync="popupActiveDetails">
            <form>

                <div class="vx-row mt-10 mb-5">
                    <div class="vx-col sm:w-1/4 w-full">
                        <h4>المعرّف : </h4>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full mb-2">
                        <h4>{{ order.id }}</h4>
                    </div>
                </div>
                <hr style="height:1px;border-width:0;background-color:#BDBAC2">

                <div class="vx-row mt-10 mb-5">

                    <div class="vx-col sm:w-1/4 w-full">
                        <h4>حالة الطلب : </h4>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full mb-2">
                        <h4>{{ order.arabic_status }}</h4>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full">
                        <h4>نوع الطلب : </h4>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full mb-2">
                        <h4>{{ order.order_type }}</h4>
                    </div>
                </div>
                <div class="vx-row mt-10 mb-5">
                    <div class="vx-col sm:w-1/2 w-full">
                        <h4>الوقت المتوقع لتوصيل الطلب : </h4>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full mb-2">
                        <h4>{{ order.expected_delivery_date }}</h4>
                    </div>
                </div>
                <div class="vx-row mt-10 mb-5" v-if="order.message">
                    <div class="vx-col sm:w-1/2 w-full">
                        <h4>الملاحظات المرسلة للزبون:</h4>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                        <h4>{{ order.message }}</h4>
                    </div>
                </div>
                <div class="vx-row mt-10 mb-5" v-if="order.rejection_reason">
                    <div class="vx-col sm:w-1/4 w-full">
                        <h4>سبب الرفض:</h4>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full mb-2">
                        <h4>{{ order.rejection_reason }}</h4>
                    </div>
                </div>
                <div class="vx-row mt-10 mb-5">
                    <div class="vx-col sm:w-1/2 w-full">
                        <h4>التكلفة الإجمالية : </h4>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full mb-2">
                        <h4>{{ order.total_cost }}</h4>
                    </div>
                </div>
                <hr style="height:1px;border-width:0;background-color:#BDBAC2">
                <div class="vx-row mt-10">
                    <div class="vx-col sm:w-1/3 w-full">
                        <h4>اسم الزبون
                        </h4>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <h4>{{ order.user.full_name }}</h4>
                    </div>
                </div>

                <div class="vx-row mt-10 mb-5">
                    <div class="vx-col sm:w-1/3 w-full">
                        <h4>عنوان الزبون
                        </h4>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <h4>{{ order.user_address.name }}/{{order.user_address.address}}</h4>
                    </div>
                </div>
                <div class="vx-row mt-10 mb-5">
                    <div class="vx-col sm:w-1/3 w-full">
                        <h4>رقم الزبون</h4>
                    </div>
                    <div class="vx-col sm:w-1/3 w-full " dir="ltr">
                        <h4>{{ order.user.mobile }}</h4>
                    </div>
                </div>

                <hr style="height:1px;border-width:0;background-color:#BDBAC2">

                <div class="vx-row mt-10 mb-5" v-if="order.donation_order">
                    <div class="vx-col sm:w-1/2 w-full">
                        <h4>وصف الهدية
                        </h4>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full">
                        <h4>{{ order.donation_order.gift_desc }}</h4>
                    </div>
                    <hr style="height:1px;border-width:0;background-color:#BDBAC2">
                </div>

                <div class="vx-row mt-10 mb-5" v-if="order.friend_gift">
                    <div class="vx-col sm:w-1/2 w-full mb-5">
                        <h4>اسم الصديق
                        </h4>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full">
                        <h4>{{ order.friend_gift.name }}</h4>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full mb-5">
                        <h4>الرسالة المرفقة
                        </h4>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full">
                        <h4>{{ order.friend_gift.message }}</h4>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full mb-5">
                        <h4>رقم الصديق
                        </h4>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full">
                        <h4>{{ order.friend_gift.mobile }}</h4>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full mb-5">
                        <h4>عنوان الصديق
                        </h4>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full">
                        <h4>{{ order.friend_gift.address }}</h4>
                    </div>
                </div>


                <hr style="height:1px;border-width:0;background-color:#BDBAC2">
                <div class="vx-row mt-10">
                    <div class="vx-col w-full">
                        <h4>ملاحظات الزبون: </h4>
                    </div>
                    <div class="vx-col w-full">
                        <h4>{{ order.user_note }}</h4>
                    </div>
                </div>

                <div class="vx-row mt-10">
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                        <vs-button ycolor="success" type="filled" @click="popupActiveDetails=false">حسناً
                        </vs-button>
                    </div>
                </div>

            </form>

        </vs-popup>
        <vs-popup :title="this.statusAction" :active.sync="popupActive">
            <vs-progress v-if="isLoading" indeterminate color="primary"></vs-progress>
            <ValidationObserver v-slot="{handleSubmit}" ref="validationObserver">
                <form>
                    <div class="vx-row mt-5 justify-center">
                        <h3 > سيتم تغيير حالة الطلب من</h3>
                        <h3 :style="{'color':fromStatusColor }" class="vx-col">{{fromStatus}}</h3>
                        <h3> إلى </h3>
                        <h3 :style="{'color':toStatusColor }" class="vx-col">{{toStatus}}</h3>
                    </div>
                    <div class="vx-row mt-5">
                        <div class="vx-col w-full mb-2">
                            <ValidationProvider name="الرسالة" :rules="`${(newStatus === 'Rejected') ? 'required' :''}`"   v-slot="{errors}">
                            <label>الرسالة</label>
                            <vs-input placeholder="الرسالة" v-model="message" class="w-full"/>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="vx-row mt-5">
                        <div class="vx-col sm:w-1/2 w-full mb-2">
                            <vs-button :disabled="isLoading" ycolor="success" type="filled" @click.prevent="handleSubmit(onSubmit)">
                                إرسال
                            </vs-button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </vs-popup>
    </div>
</template>

<script>
    import AgTable from '../../../shared/shared-components/ag-grid/AgTable';
    import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell";
    import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
    import vSelect from 'vue-select'
    import imageUploader from '../../../shared/shared-components/ImageUploader'
    import 'quill/dist/quill.snow.css'
    import {quillEditor} from 'vue-quill-editor'
    import AgIcon from "@/app/ecommerce/ecommerce-components/AgIcon";
    import AgTableDangerIcon from "@/app/ecommerce/ecommerce-components/AgTableDangerIcon";
    import utilities from "@/app/shared/utilities";
    import OrderStatus from '../../ecommerce-components/OrderStatus'

    const orderRepo = RepositoryFactory.get('order');

    export default {
        name: "PersonalOrder",
        components: {
            AgTable,
            vSelect,
            imageUploader,
            quillEditor,
            OrderStatus
        },
        data() {
            return {
                isLoading: true,
                gridOptions: null,
                rowData: [],
                popupActiveDetails: false,
                popupActive: false,
                imageRequired: true,
                imagePreview: null,
                dialogTitle: String,
                statusAction:'',
                newStatus:'',
                fromStatus:'',
                fromStatusColor :'',
                toStatus:'',
                toStatusColor:'',
                orderStatusAction:'',
                order: {
                    id: null,
                    status: '',
                    arabic_status:'',
                    is_late:'',
                    user_note: '',
                    orderable: '',
                    message:'',
                    rejection_reason:'',
                    order_type: '',
                    expected_delivery_date:'',
                    user: {},
                    user_address: {},
                    donation_order: {},
                    friend_gift: {}
                },
                orderId :'',
                message :'',
                type:'',
            }
        },
        methods: {
            createColDefs() {
                let self = this;
                return [
                    {
                        headerName: 'المعرف',
                        field: 'id',
                        filter: 'agTextColumnFilter',
                        suppressSizeToFit: true,
                        imagePreview: null,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                    },
                    {
                        headerName: 'خيارات الطلب',
                        valueGetter:
                            function getObject(params) {
                                return params
                            },
                        cellRendererFramework: AgIcon,
                        cellRendererParams: {
                            icon: 'ChevronsLeftIcon',
                            secondIcon :'XCircleIcon',
                            thirdIcon: 'EyeIcon',
                            click(valueGetter)
                            {
                                self.changeOrderStatus(valueGetter)
                            },
                            clickSecondIcon(valueGetter) {
                                self.changeOrderStatus(valueGetter,true)
                            },
                            clickThirdIcon(valueGetter) {
                                self.rowClicked(valueGetter.data.id)
                            }
                        }
                    },
                    {
                        headerName: 'حالة الطلب',
                        field: 'arabic_status',
                        filter: 'agTextColumnFilter',
                        cellStyle: self.changeCellColor,
                    },
                    {
                        headerName: 'اسم الزبون',
                        field: 'user.full_name',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'رقم الزبون',
                        field: 'user.mobile',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'عنوان الزبون',
                        field: 'user_address.address',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'الطلب متأخر',
                        cellRendererFramework: AgTableDangerIcon,
                        field: 'is_late',
                        cellRendererParams: {
                            icon: 'AlertTriangleIcon'
                        }
                    },
                    {
                        headerName: 'نوع الطلب',
                        field: 'order_type',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'التكلفة الإجمالية',
                        field: 'total_cost',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'الوقت المتوقع للتوصيل',
                        field: 'expected_delivery_date',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'ملاحظات من الزبون',
                        field: 'user_note',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'ملاحظات للزبون',
                        field: 'message',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'سبب الرفض',
                        field: 'rejection_reason',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'الإجراءات',
                        field: 'id',
                        cellRendererFramework: AgTableBtnCell,
                        cellRendererParams: {
                            viewRecordDetails: function (record) {
                                self.viewRecordDetails(record)
                            },
                            actions: ['view']
                        },
                    },
                ];
            },
            rowClicked(id) {
                this.$router.push(`/ecommerce/orders/${id}/order_details`)
            },
            changeCellColor(params) {
                return {'color': utilities.setOrderStatusColor(params.node.data.status)};
            },
            changeOrderStatus(record ,isRejected = false){
                this.orderId = record.data.id;
                let statusObject = utilities.changeOrderStatus(record.data.status,isRejected);
                this.statusAction = statusObject.statusAction;
                this.newStatus = statusObject.newStatus;
                this.fromStatus = statusObject.fromStatus;
                this.toStatus = statusObject.toStatus;
                this.fromStatusColor = statusObject.fromStatusColor;
                this.toStatusColor = statusObject.toStatusColor;
                if(this.newStatus != null) {
                    this.openPopup();
                }
            },
            openPopup() {
                this.$refs.validationObserver.reset();
                this.popupActive = true;
            },
            onSubmit(){
                this.isLoading = true;
                orderRepo.changeOrderStatus(this.orderId,this.newStatus,this.message).then((response) => {
                    let index = this.rowData.map((m) => {
                        return m.id;
                    }).indexOf(response.data.data.id);
                    if(response.data.data.status === 'Delivered'|| response.data.data.status === 'Rejected')
                        this.rowData.splice(index, 1);
                    else
                    this.rowData[index] = response.data.data;
                    this.rerender();
                    this.popupActive = false;
                    this.isLoading = false;
                });
            },
            viewRecordDetails(record) {
                this.popupActiveDetails = true;
                this.order = record;
            },
            // used to rerender updated row
            rerender() {
                this.gridApi.setRowData(this.rowData)
            },
            fetchAllOrders(ordersType,isCompleted = false) {
                this.type = ordersType;
                orderRepo.fetchAllOrders(ordersType,isCompleted).then((data) => {
                    this.rowData = data;
                    this.isLoading = false;
                });
            },
        },
        created() {
            // prepare ag grid options
            this.gridOptions = {};
            this.gridOptions.columnDefs = this.createColDefs();
            this.gridOptions.defaultColDef ={resizable: false,};
            this.fetchAllOrders('orphan_gift_orders');

        },
        mounted() {
            this.gridApi = this.gridOptions.api;
        },
    }
</script>

<style scoped>

</style>
