<template>
  <div>
    <ValidationProvider
      name="الصورة"
      ref="imageValidation"
      :rules="localRules"
      v-slot="{errors}"
    >
      <div :class="errors[0] ? imageRequiredClass : ''">
        <file-pond
          name="file"
          ref="pond"
          :chunk-uploads="true"
          label-idle="Drop files here or <span class='filepond--label-action'>Browse</span>"
          accepted-file-types="image/jpeg, image/png, image/jpeg"
          :allow-image-preview='false'
          :server="server"
          :files="[]"
          :onaddfilestart="addfilestart"
          :onprocessfiles="filesUploaded"
          :onprocessfile="successUpload"
          :allow-multiple="multiple"
          :max-files="5"
          :image-resize-target-width="820"
          :image-resize-target-height="480"
          :image-resize-upscale="false"
          :image-transform-output-quality="70"
        />
      </div>
      <p
        style="text-align: center"
        class="text-danger">{{ errors[0] }}</p>
    </ValidationProvider>
    <vs-row>
      <template v-if="multiple">
        <vs-col
          class="p-2"
          vs-type="flex"
          vs-justify="end"
          vs-align="center"
          vs-lg="3"
          vs-sm="6"
          vs-xs="12"
          v-for="(file, index) in files"
          :key="index"
        >
          <vx-card class="overlay-card overflow-hidden">
            <template slot="no-body">
              <img
                :src="file"
                alt="user-profile-cover"
                class="responsive"
                style="height: 300px; object-fit: cover">
              <div class="card-overlay text-white flex flex-col">
                <div class="flex flex-row-reverse">
                  <vs-button
                    color="rgb(255, 255, 255)"
                    size="small"
                    type="border"
                    @click="clearFile(index)"
                    v-show="file"
                    icon-pack="feather"
                    icon="icon-trash"/>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>
      </template>
      <template v-else-if="file !== null">
        <vs-col
          vs-type="flex"
          vs-justify="end"
          vs-align="left"
          vs-xs="12">
          <vx-card class="overlay-card overflow-hidden">
            <template slot="no-body">
              <img
                :src="file"
                alt="user-profile-cover"
                class="responsive"
                style="height: 300px; object-fit: cover">
              <div class="card-overlay text-white flex flex-col">
                <div class="flex flex-row-reverse">
                  <vs-button
                    color="rgb(255, 255, 255)"
                    size="small"
                    type="border"
                    @click="clearFile()"
                    v-show="file"
                    icon-pack="feather"
                    icon="icon-trash"/>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>
      </template>
    </vs-row>
  </div>
</template>

<script>
  import vueFilePond from "vue-filepond";
  import "filepond/dist/filepond.min.css";
  import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
  import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
  import FilePondPluginImagePreview from "filepond-plugin-image-preview";
  import FilePondPluginImageResize from 'filepond-plugin-image-resize';
  import FilePondPluginImageTransform from 'filepond-plugin-image-transform';

  import {environment} from "@/environment";

  const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginImageResize,
    FilePondPluginImageTransform
  );
  export default {
    name: "FilePondComponent",
    props: {
      files: {
        type: Array,
        default: undefined
      },
      file: {
        type: String,
        default: undefined
      },
      project: {
        type: String,
        required: true
      },
      rules: {
        type: Object,
        default: () => ({required: true})
      },
      env: {
        type: String,
        default: 'local'
      },
      multiple: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        localRules: this.rules,
        imageRequiredClass: 'imageRequiredClass',
        server: {
          process: `${environment.apiBaseURL}files/projects/${this.project}`,
          fetch: null
        },
        uploadedFiles: [],
        tempRules: ''
      }
    },
    methods: {
      successUpload(error, file) {
        this.uploadedFiles.push(JSON.parse(file.serverId).data.file);
      },
      filesUploaded() {
        const files = this.multiple ? this.uploadedFiles : this.uploadedFiles[0];
        this.$emit('fileUploaded', files)
        this.$refs.imageValidation.reset();
        this.uploadedFiles = [];
        this.tempRules = this.localRules;
        this.makeRequiredFalse()
      },
      addfilestart() {
        this.$emit('uploading')
      },
      clearFile(index = undefined) {
        if (this.uploadedFiles.length === 0) this.makeRequiredFalse();
        this.$emit('fileDeleted', index);
      },
      validateProps() {
        if (this.multiple && !this.files)
          console.error('files prop in FilePondComponent required');
        else if (!this.multiple && !this.file) {
          console.error('file prop in FilePondComponent required');
        }
      },
      initValidation() {
        this.localRules = this.tempRules
      },
      makeRequiredFalse() {
        this.localRules = {...this.localRules, required: false};
      }
    },
    components: {
      FilePond
    },
    created() {
      if ((this.files && this.files.length > 0) || this.file)
        this.makeRequiredFalse()
      this.validateProps();
    }
  }
</script>
<style>
  .filepond--root {
    margin-bottom: 1px;
  }

  .imageRequiredClass {
    border: 2px solid red;
    border-radius: 10px
  }

  .vx-card .vx-card__collapsible-content .card-overlay::after {
    margin: auto;
    background: rgba(0, 0, 0, 0.54);
  }
</style>
