<template>
  <div :style="{'direction':'rtl'}">

    <vs-dropdown class="cursor-pointer" vs-trigger-click>

        <feather-icon icon="MoreVerticalIcon" svgClasses="h-5 w-5"/>

      <vs-dropdown-menu>

        <vs-dropdown-item @click="viewRecordDetails" v-show="actions.includes('view')">
          <feather-icon icon=EyeIcon
                        svgClasses="h-5 w-5 hover:text-success cursor-pointer"/>
          <span class="ml-2 h5">عرض التفاصيل </span>
        </vs-dropdown-item>

        <vs-dropdown-item @click="editRecord" v-show="actions.includes('edit')">
          <feather-icon icon="Edit3Icon" class="mr-auto"
                        svgClasses="h-5 w-5 hover:text-primary cursor-pointer"/>
          <span class="ml-2 h5">تعديل </span>
        </vs-dropdown-item>

        <vs-dropdown-item @click="confirmDeleteRecord" v-show="actions.includes('delete')&& isAdmin()">
          <feather-icon icon="Trash2Icon"
                        svgClasses="h-5 w-5 hover:text-danger cursor-pointer"/>
          <span class="ml-2 h5">حذف</span>
        </vs-dropdown-item>

        <vs-dropdown-item @click="confirmDeleteImage(deleteImage)" v-show="actions.includes('image-delete')">
          <feather-icon icon="ImageIcon"
                        svgClasses="h-5 w-5 hover:text-danger cursor-pointer"/>
          <span class="ml-2 h5">حذف الصورة الأساسية</span>
        </vs-dropdown-item>

        <vs-dropdown-item @click="confirmDeleteImage(deletePreviewImage)"
                          v-show="actions.includes('image-preview-delete')">
          <feather-icon icon="ImageIcon"
                        svgClasses="h-5 w-5 hover:text-danger cursor-pointer"/>
          <span class="ml-2 h5">حذف الصورة الثانوية</span>
        </vs-dropdown-item>

      </vs-dropdown-menu>
    </vs-dropdown>


  </div>
</template>

<script>
  import Vue from "vue";

  export default Vue.extend({
    name: "AgTableBtnCell",
    data() {
      return {
        actions: ['view', 'edit', 'delete', 'image-delete']
      }
    },
    methods: {
      confirmDeleteRecord() {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'هل أنت متأكد من الحذف؟',
          text: `في حال الموافقة, لن تتمكن من التراجع!`,
          accept: this.deleteRecord,
          acceptText: 'حذف'
        });
      },
      confirmDeleteImage(action) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'هل أنت متأكد من حذف الصورة؟',
          text: `في حال الموافقة, لن تتمكن من التراجع!`,
          accept: action,
          acceptText: 'حذف'
        });
      },
      deleteRecord() {
        this.params.deleteRecord(this.params.value, this.params.rowIndex);
      },
      deleteImage() {
        this.params.deleteImage(this.params.value, this.params.rowIndex);
      },
      deletePreviewImage() {
        this.params.deletePreviewImage(this.params.value, this.params.rowIndex);
      },
      editRecord() {
        this.params.editRecord(this.params.data);
      },
      viewRecordDetails() {
        this.params.viewRecordDetails(this.params.data);
      },
      isAdmin(){
        const roles = JSON.parse(localStorage.getItem('user')).user.roles.map((role) => role.name);
        if(roles.includes('super_admin'))
          return true;
        else return false;
      }
    },
    created() {
      if (this.params.actions) this.actions = this.params.actions
    }
  });
</script>

<style scoped>

</style>
