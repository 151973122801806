<template>
  <div>

    <ag-table :gridOptions="gridOptions"
              :rowData="rowData"
              @emitAddNewRecord="createRecord()"
    >
    </ag-table>
    <vs-popup title="تفاصيل المتجر" :active.sync="popupActive">
      <vs-progress v-if="isLoading" indeterminate color="primary"></vs-progress>
      <ValidationObserver v-slot="{handleSubmit}" ref="validationObserver">
        <form>
          <!-- Logo -->

          <div class="vx-row">
            <div class="vx-col  w-full text-center">
              <h4 class="my-5">الشعار</h4>
              <image-uploader @fileUploaded="fileUploaded"
                              :imagePreview="imagePreview"
                              :isImageRequired="imageRequired"
                              ref="imageUploader"/>
            </div>
          </div>
          <div class="vx-row justify-center">
            <div class="vx-col w-full">
              <ValidationProvider name="الاسم" rules="required" v-slot="{ errors }">
                <label for="">الاسم</label>
                <vs-input placeholder="الاسم" name="name"
                          v-model="market.name"
                          class="w-full"
                ></vs-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vs-row">
            <div class="vx-col w-full">
              <ValidationProvider name="الشرح" rules="required" v-slot="{ errors }">
                <label for="">شرح</label>
                <quill-editor v-model="market.desc"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button :disabled="isLoading" ycolor="success" type="filled" @click.prevent="handleSubmit(onSubmit)">
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>

    <vs-popup title="تفاصيل المتجر" :active.sync="popupActiveDetails">
      <form>
        <div class="vx-row">
          <img :src="market.logo_url" class="m-auto custom-image-size">
        </div>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>المعرّف : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ market.id }}</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <h4>الاسم : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ market.name }}</h4>
          </div>
        </div>

        <div class="vx-row my-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>التفاصيل : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4 v-html="market.desc"></h4>
          </div>
        </div>

        <div class="vx-row mt-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-button ycolor="success" type="filled" @click="popupActiveDetails=false">حسناً
            </vs-button>
          </div>
        </div>

      </form>

    </vs-popup>

  </div>
</template>

<script>
import AgTable from '../../../shared/shared-components/ag-grid/AgTable';
import Loader from "@/app/shared/shared-components/Loader";
import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell";
import AgTableImageCell from '../../../shared/shared-components/ag-grid/AgTableImageCell'
import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
import vSelect from 'vue-select'
import imageUploader from '../../../shared/shared-components/ImageUploader'
import utilities from '../../../shared/utilities/index'
import 'quill/dist/quill.snow.css'
import {quillEditor} from 'vue-quill-editor'

const marketRepo = RepositoryFactory.get('market');

export default {
  name: "Market",
  components: {
    AgTable,
    Loader,
    vSelect,
    imageUploader,
    quillEditor
  },
  data() {
    return {
      isLoading: true,
      gridOptions: null,
      rowData: [],
      popupActiveDetails: false,
      popupActive: false,
      imageRequired: true,
      imagePreview: null,
      dialogTitle: String,
      market: {
        id: null,
        name: '',
        logo_url: '',
        desc: '',
        social_media_links: [{
          key: 'twitter',
          value: 'Bassel@twitter.com'
        }],
      }

    }
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          imagePreview: null,
          width: 250,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: 'الاسم',
          field: 'name',
          filter: 'agTextColumnFilter',
          width: 250,
        },
        {
          headerName: 'الشعار',
          field: 'logo_url',
          cellRendererFramework: AgTableImageCell,
          width: 250,
          cellRendererParams: {
            width:'40%'
          }
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          width: 250,
          cellRendererParams: {
            editRecord: function (market) {
              self.editRecord(market)
            },
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record)
            },
            actions: ['view', 'edit']
          },

        },
      ];
    },
    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.market = record;
    },
    fileUploaded(file) {
      this.market.logo_url = file;
    },
    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData)
    },
    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },
    // used to fire the dialog which response to add a new record in the table
    createRecord() {
      utilities.initObjectMembers(this.market);
      this.$refs.imageUploader.clearImage();
      this.imagePreview = null;
      this.dialogTitle = 'اضافة متجر';
      this.openPopup();
    },

    // used to fire the dialog which response to edit current record in the table
    editRecord(market) {
      this.$refs.imageUploader.clearImage();
      this.dialogTitle = 'تعديل متجر';
      Object.assign(this.market, market);
      this.imagePreview = this.market.logo_url;
      this.openPopup();
    },

    onSubmit() {
      this.isLoading = true;
      if (typeof this.market.logo_url == "string")
        delete this.market.logo_url;
      if (this.market.id !== null) {
        this.updateMarket(this.market);
      } else {
        this.storeMarket(this.market);
      }
    },

    storeMarket(market) {
      marketRepo.storeMarket(this.$objectToFD(market)).then((response) => {
        this.rowData.unshift(response.data.data);
        this.popupActive = false;
        this.isLoading = false;
      });
    },
    updateMarket(market) {
      marketRepo.updateMarket(this.$objectToFD(market), market.id).then((response) => {
        let index = this.rowData.map((m) => {
          return m.id;
        }).indexOf(response.data.data.id);
        this.rowData[index] = response.data.data;
        this.rerender();
        this.popupActive = false;
        this.isLoading = false;
      });
    },
    fetchAllMarkets() {
      marketRepo.fetchAllMarkets().then((data) => {
        this.rowData = data;
        this.isLoading = false;
      });
    },
  },
  created() {
    // prepare ag grid options
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllMarkets();

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
}
</script>

<style scoped>

</style>
