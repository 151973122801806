import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const url = `${environment.ecommerceApiBaseUrl}variantValues`;
const url2 = `${environment.ecommerceApiBaseUrl}variantKey`;

export default {
  getAllVariant() {
    return httpClient.get(url)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },
  get(id) {
    const URL = `${url2}/${id}/variantValues`;
      return httpClient.get(URL)
          .then(res => res.data)
          .catch(error => console.log(error));
  },
  storeVariant(variantValue) {
    return httpClient.post(url, variantValue)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  updateVariant(variantValue) {

    return httpClient.post(`${url}/${variantValue.id}?_method=PUT`, variantValue)
      .then(res => res.data)
      .catch(error => console.log(error));
  },

  deleteVariant(ids) {
    return httpClient.delete(`${url}/${ids}`)
      .then(res => res.data)
      .catch(error => console.log(error));
  }
}
