<template>
  <div>
    <vs-card>
      <vs-row vs-justify="space-between" vs-type="flex">
        <vs-col vs-align="center" vs-justify="center" vs-type="flex" vs-w="6">
          <import-excel :onSuccess="importExcelFile"></import-excel>
        </vs-col>
        <vs-col vs-align="center" vs-justify="center" vs-type="flex" vs-w="6">
          <div @click="exportExcelFile"
               class="px-3 py-3 cursor-pointer text-center border-2 border-dashed d-theme-border-primary d-theme-dark-bg text-xl">
            <feather-icon class="block" icon="DownloadCloudIcon"
                          svgClasses="h-8 w-8 stroke-current text-primary"/>
            <span class="text-primary">تصدير إلى ملف اكسل</span>
          </div>
        </vs-col>
      </vs-row>
    </vs-card>
    <ag-table :dataSourceApi="getAll"
              :gridOptions="gridOptions"
              :rtl=false
              @emitAddNewRecord="createRecord()"
              @emitDeleteMultipleRecords="deleteProduct($event, null , true)"
              @emitShowArchivedRecords=""
              @emitShowCurrentRecords=""/>
    <store-popup
      :product="product"
      @created="productCreated"
      @updated="productUpdated"
      ref="storePopupRef"
    />
  </div>
</template>

<script>
  import {RepositoryFactory} from '../../repositories/ecommerceRepositoryFactory'
  import AgTable from '../../../shared/shared-components/ag-grid/AgTableServerSide.vue'
  import AgTableBtnCell from "../../../shared/shared-components/ag-grid/AgTableBtnCell";
  import StorePopup from './StorePopup'
  import AgButton from "../../../shared/shared-components/ag-grid/AgButton";
  import ImportExcel from "../../../../components/excel/ImportExcel";

  const productRepository = RepositoryFactory.get("product");
  const subProductRepository = RepositoryFactory.get("subProduct");

  export default {
    name: "Product",
    data() {
      return {
        gridApi: null,
        product: {
          name: null,
          category: {
            id: null,
            name: null
          }
        },
        gridOptions: null,
        isLoading: true,
        popupActive: false
      }
    },

    methods: {
      createRecord() {
        this.init();
        this.$refs.storePopupRef.open();
      },
      init() {
        this.product = {
          name: null,
          category: {
            id: null,
            name: null
          }
        }
      },
      editRecord(product) {
        this.product = product;
        this.$refs.storePopupRef.open();
      },
      deleteProduct(ids, index, isMultiple = false) {
        if (isMultiple)
          ids = ids.join(',');
        productRepository.delete(ids).then((response) => {
          if (response.code === 200) {
            this.gridApi.refreshServerSideStore({purge: true});
          }
          this.isLoading = false;
        })
      },
      productCreated() {
        this.gridApi.refreshServerSideStore({purge: true});
      },

      productUpdated(product) {
        this.gridApi.refreshServerSideStore({purge: true});
      },

      getAll(query) {
        return productRepository.getAll(query).then((response => response));
      },
      createColDefs() {
        let self = this;
        return [
          {
            headerName: 'المعرف',
            field: 'id',
            suppressSizeToFit: true,
            checkboxSelection: true,
          },
          {
            headerName: 'الاسم',
            field: 'name',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'الصنف',
            field: 'category.name',
          },
          {
            headerName: 'المحل',
            field: 'category.store.name',
          },
          {
            headerName: 'العدد الكلي',
            field: 'count',
          },
          {
            headerName: 'المنتجات الفرعية',
            cellRendererFramework: AgButton,
            valueGetter:
              function getObject(params) {
                return params.data.id
              },
            cellRendererParams: {
              size: 'small',
              color: 'primary',
              name: 'مشاهدة المنتجات الفرعية',
              type: 'gradient',
              click(valueGetter) {
                self.rowClicked(valueGetter)
              }
            }
          },
          {
            headerName: 'الإجراءات',
            valueGetter:
              function getObject(params) {
                return params.data.id
              },
            cellRendererFramework: AgTableBtnCell,
            cellRendererParams: {
              deleteRecord: function (valueGetter, rowIndex) {
                self.deleteProduct(valueGetter, rowIndex)
              },
              editRecord: function (product) {
                self.editRecord(product)
              },
              actions: ['edit', 'delete']
            },
          },
        ];
      },
      rowClicked(id) {
        this.$router.push(`/ecommerce/products/${id}/sub_products`)
      },
      importExcelFile(excelData) {
        subProductRepository.updateSubProductsFromExcel(excelData.rawFile).then(() => {
          this.gridApi.refreshServerSideStore({purge: true});
          this.isLoading = false;
        });
      },
      exportExcelFile() {
        subProductRepository.exportSubProductsFromExcel().then(() => {
          this.isLoading = false;
        });
      }
    },

    components: {
      AgTable,
      StorePopup,
      ImportExcel
    },

    created() {
      this.gridOptions = {};
      this.gridOptions.columnDefs = this.createColDefs();
    },
    mounted() {
      this.gridApi = this.gridOptions.api;
    }
  }
</script>
