import app from '../../../main'

export default {
  start() {
    app.$Progress.start()
  },
  finish() {
    app.$Progress.finish();
  },
  getProgressBarOptions() {
    return {
      color: '#4b364e',
      failedColor: '#874b4b',
      thickness: '5px',
      transition: {
        speed: '0.2s',
        opacity: '1s',
        termination: 300
      },
      autoRevert: true,
      location: 'top',
      inverse: false
    }
  }
}
