<template>
    <div>
    <vs-card>
        <vs-row vs-type="flex" vs-justify="center">
            <h3 class="text-primary">
                حالات الطلب
            </h3>
        </vs-row>
        <vs-row vs-type="flex" vs-justify="center">
            <div class="demo-alignment">
                <vs-chip color="#FE948E" >
                    قيد المراجعة
                </vs-chip>
                <vs-chip  color="#5F7A99">
                    مقبول
                </vs-chip>
                <vs-chip color="#8158A4">
                    جاهز
                </vs-chip>
                <vs-chip  v-show="isShowAllStatus" color="#6495ED">
                    قيد التوصيل
                </vs-chip>
                <div v-show="isShowAllStatus">
                    <vs-chip  v-show="isShowAllStatus"  color="#9ECC3B">
                        تم التوصيل
                    </vs-chip>
                </div>

            </div>
        </vs-row>
        <vs-row vs-type="flex" vs-justify="center">
            <div class="demo-alignment">
                <vs-chip  color="#EF4141">
                    تم الرفض
                </vs-chip>
            </div>
        </vs-row>
    </vs-card>
    </div>
</template>

<script>
    export default {
        name: "OrderStatus",
        props: {
            isShowAllStatus : {required: true , type:Boolean},
    }
    }
</script>

<style scoped>

</style>