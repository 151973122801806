import Vue from "vue";
import router from '../../../router'
export default {
    options: {
        theme: 'bubble',
        position: "bottom-left",
        duration: 3000,
        singleton: true
    },
    notificationOptions:{
        theme: 'bubble',
        position: "bottom-left",
        singleton: true,
        action : {
            text : 'موافق',
            onClick : (e, toastObject) => {
                if (Notification.permission !='denied') {
                    toastObject.goAway(0);
                   router.push({name: 'home'})
                }
            }
        }
    },
    success(message) {
        Vue.toasted.success(message, this.options)
    },
    failed(message) {
        Vue.toasted.error(message, this.options)
    },
    notify(message){
        Vue.toasted.error(message, this.notificationOptions)
    }
}
