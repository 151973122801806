<template>
  <ValidationProvider :name="name" :rules="validationRules" v-slot="{ errors }">
    <label for="">{{name}}</label>
    <v-select :options="options"
              :label="label"
              v-model="computedValue"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              :reduce="reduce"
              :multiple="multiple"
              :placeholder="placeholder"/>
    <span class="text-danger">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
  import vSelect from 'vue-select'

  export default {
    name: "TmInput",
    props: {
      name: {type: String, required: true},
      value: {type: [Number, String,Array,Object]},
      placeholder: {type: String},
      validationRules: {type: String, required: true},
      options: {type: Array, required: true},
      label: {type: String},
      reduce: {type: Function},
      multiple:{type: Boolean}
    },
    computed: {
      computedValue: {
        get() {
          return this.value
        },
        set(newValue) {
          this.$emit("input", newValue)
        }
      }
    },

    components: {
      vSelect
    }
  }
</script>
