<template>
        <svg height="25px" viewBox="-21 -47 682.66669 682" width="25px" :style="{ fill: fillColor}"   xmlns="http://www.w3.org/2000/svg"><path d="m552.011719-1.332031h-464.023438c-48.515625 0-87.988281 39.472656-87.988281 87.988281v283.972656c0 48.421875 39.300781 87.824219 87.675781 87.988282v128.871093l185.183594-128.859375h279.152344c48.515625 0 87.988281-39.472656 87.988281-88v-283.972656c0-48.515625-39.472656-87.988281-87.988281-87.988281zm-83.308594 330.011719h-297.40625v-37.5h297.40625zm0-80h-297.40625v-37.5h297.40625zm0-80h-297.40625v-37.5h297.40625zm0 0"/></svg>
</template>

<script>
    export default {
        name: "MessageIcon",
        props: {
            fillColor : {required: true},
        }
    }
</script>

<style scoped>

</style>