/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'
import progressBar from "./app/shared/utilities/progressBar";

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)

// VeeValidate
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import Validations from './plugins/veeValidate/Vee-validate';
// register the plugin on vue
import Toasted from 'vue-toasted';
import VueProgressBar from 'vue-progressbar'

import firebaseMessaging from './firebase';
Vue.prototype.$messaging = firebaseMessaging;
Validations.initValidations()
Vue.use(Toasted)
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.use(VueProgressBar, progressBar.getProgressBarOptions());
// axios
import axios from "./axios.js"

Vue.prototype.$http = axios

// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// Vuejs - Vue wrapper for hammerjs
import {VueHammer} from 'vue2-hammer'

Vue.use(VueHammer)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')

import ObjectToFD from 'vue-object-to-formdata';
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

// name is optional
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(ObjectToFD);

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import loadingOverlay from "./app/shared/utilities/loadingOverlay";
Vue.use(VueLoading, loadingOverlay.getOverlayOptions());
Vue.config.productionTip = false

export default new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
