import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.ecommerceApiBaseUrl}products`;
export default {
  getAll(query) {
    const url = query !== undefined ? URL + query : URL;
    return httpClient.get(url)
      .then(res => res.data)
      .catch(error => console.log(error));

  },
  get(id){
    const url =`${URL}/${id}`;
    return httpClient.get(url)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  store(product) {
    product = {
      name: product.name,
      category_id: product.category.id
    };
    return httpClient.post(URL, product)
      .then(res => res.data)
      .catch(error => console.log(error));
  },

  update(product) {
    product = {
      id: product.id,
      name: product.name,
      category_id: product.category.id
    };
    return httpClient.post(`${URL}/${product.id}?_method=PUT`, product)
      .then(res => res.data)
      .catch(error => console.log(error));
  },

  delete(ids) {
    return httpClient.delete(`${URL}/${ids}`)
      .then(res => res.data)
      .catch(error => console.log(error));
  },

  getSubProducts(id){
    const url = `${URL}/${id}/sub-products`;
    return httpClient.get(url)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
}
