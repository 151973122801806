<template>
    <feather-icon v-if="params.value"  :icon="params.icon"  :svgClasses="['h-8 w-8 text-danger']" class="mb-2" />
    <h3 v-else >-</h3>

</template>

<script>
    import Vue from "vue";
    export default Vue.extend({
        name: "AgTableDangerIcon"
        })
</script>

<style scoped>

</style>