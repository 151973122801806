import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.ecommerceApiBaseUrl}subProducts`;

export default {
  getAll() {
    return httpClient.get(URL)
        .then(res => res.data)
        .catch(error => console.log(error));
  },
  get(id) {
    const url =`${URL}/${id}`;
    return httpClient.get(url)
        .then(res => res.data)
        .catch(error => console.log(error));
  },
  store(subProducts) {
      const url = URL + '/many';
    return httpClient.post(url, subProducts)
        .then(res => res.data)
        .catch(error => console.log(error));
  },
  update(subProduct) {
    return httpClient.post(`${URL}/${subProduct.id}?_method=PUT`, subProduct)
        .then(res => res.data)
        .catch(error => console.log(error));
  },
  delete(ids) {
    return httpClient.delete(`${URL}/${ids}`)
        .then(res => res.data)
        .catch(error => console.log(error));
  },
  updateSubProductsFromExcel(excelData){
    const url =  environment.ecommerceApiBaseUrl +`update-excel/subProducts`;
    let excelFile = new FormData();
    excelFile.append('subproducts_excel', excelData);
    return httpClient.post(url,excelFile)
        .then(res => res)
        .catch(error => console.log(error))
  },
  exportSubProductsFromExcel(){
    const url =`${environment.ecommerceApiBaseUrl}export-excel/subProducts`;
    return httpClient.get(url,{responseType: 'arraybuffer'})
        .then(res =>{
          let fileURL = window.URL.createObjectURL(new Blob([res.data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'subProducts.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(error => console.log(error));
  },
    duplicateSubProduct(id){
        const url =  environment.ecommerceApiBaseUrl +`duplicate/${id}`;
        return httpClient.post(url)
            .then(res => res.data)
            .catch(error => console.log(error));
    },
    /*hiddenSubProduct(id) {
        const url = environment.ecommerceApiBaseUrl + `toggle-hide/subProduct/${id}`;
        return httpClient.post(url)
            .then(res => res.data.data)
            .catch(error => console.log(error));
    }*/
}
