<template>
  <div id="ag-grid-demo">

    <vx-card>

      <!-- TABLE ACTION ROW -->
      <div class="flex flex-wrap justify-between items-center">

        <!-- ITEMS PER PAGE -->
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ rowData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : rowData.length }} of {{ rowData.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
          <vs-input class="mb-4 md:mb-0 mr-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..."/>
          <!-- ACTION - DROPDOWN -->
          <vs-dropdown vs-trigger-click class="cursor-pointer">

            <div
              class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
              <span class="mr-2 leading-none">خيارات</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>

            <vs-dropdown-menu>

              <vs-dropdown-item v-show="checkAgGridEvent('emitAddNewRecord')" @click="addNewRecord()">
                                <span class="flex items-center">
                                  <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" class="mr-2"/>
                                  <span>إضافة</span>
                                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                                <span class="flex items-center" @click="gridApi.exportDataAsCsv()">
                                  <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2"/>
                                  <span>تصدير بصيغة CSV</span>
                                </span>
              </vs-dropdown-item>

              <vs-dropdown-item v-show="checkAgGridEvent('emitDeleteMultipleRecords')&& isAdmin()">
                                <span class="flex items-center" @click="confirmDeleteMultipleRecords()">
                                  <feather-icon icon="Trash2Icon" svgClasses="h-4 w-4" class="mr-2"/>
                                  <span>حذف متعدد</span>
                                </span>
              </vs-dropdown-item>
              <vs-dropdown-item v-show="checkAgGridEvent('emitShowDetails')">
                                <span class="flex items-center" @click="showDetails()">
                                  <feather-icon icon="ListIcon" svgClasses="h-4 w-4" class="mr-2"/>
                                  <span>عرض التفاصيل</span>
                                </span>
              </vs-dropdown-item>
              <vs-dropdown-item  v-show="showCompleted&&checkAgGridEvent('emitShowCompletedRecords')"  @click="showCompletedRecords()">
                                <span class="flex items-center">
                                     <feather-icon icon="KeyIcon" svgClasses="h-4 w-4" class="mr-2"/>
                                  <span>عرض الطلبات المنتهية</span>
                                </span>
              </vs-dropdown-item>
              <vs-dropdown-item  v-show="!showCompleted"  @click="showCurrentRecords()">
                                <span class="flex items-center">
                                  <feather-icon icon="ActivityIcon" svgClasses="h-4 w-4" class="mr-2"/>
                                  <span>عرض الطلبات الفعالة</span>
                                </span>
              </vs-dropdown-item>

            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div> <ag-grid-vue
      style="height: 800px"
      ref="agGridTable"
      :gridOptions="gridOptions"
      class="ag-theme-material ag-grid-table"
      :defaultColDef="defaultColDef"
      :floatingFilter="true"
      rowSelection="multiple"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :rowDragManaged="false"
      :animateRows="true"
      :modules="modules"
      @row-drag-end="onRowDragEnd"
      :rowData="rowData"
      :enableRtl=rtl
      suppressColumnVirtualisation=true>
    </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage"/>

    </vx-card>
  </div>
</template>

<script>
  import {AgGridVue} from 'ag-grid-vue'
  import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
  import utilities from "@/app/shared/utilities";
  import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';

  export default {
    components: {
      AgGridVue
    },
    props:{
      gridOptions: {
        type: Object,
        required: true
      },
      rowData: {
        type: Array,
        required: true
      },
      rtl: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        searchQuery: '',
        maxPageNumbers: 7,
        gridApi: null,
        gridColumnApi: null,
        breadcrumb: [],
        defaultColDef: {
          sortable: true,
          editable: false,
          resizable: true,
          filterParams: {
            buttons: ['apply', 'reset']
          }
        },
        showCompleted: true,
        modules: [ClientSideRowModelModule]
      }
    },
    watch: {
      '$store.state.windowWidth'(val) {
        if (val <= 576) {
          this.maxPageNumbers = 4
          this.gridOptions.columnApi.setColumnPinned('email', null)
        } else this.gridOptions.columnApi.setColumnPinned('email', 'left')
      }
    },
    computed: {
      paginationPageSize() {
        if (this.gridApi) return this.gridApi.paginationGetPageSize()
        else return 50
      },
      totalPages() {
        if (this.gridApi) return this.gridApi.paginationGetTotalPages()
        else return 0
      },
      currentPage: {
        get() {
          if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
          else return 1
        },
        set(val) {
          this.gridApi.paginationGoToPage(val - 1)
        }
      }
    },
    methods: {

      updateSearchQuery(val) {
        this.gridApi.setQuickFilter(val)
      },

      addNewRecord() {
        this.$emit('emitAddNewRecord')
      },
      showCurrentRecords() {
        this.showCompleted = true;
        this.$emit('emitShowCurrentRecords')
      },
      showCompletedRecords() {
        this.showCompleted = false;
        this.$emit('emitShowCompletedRecords')
      },
      showDetails() {
        this.$emit('emitShowDetails')
      },
      deleteMultipleRecords(recordsIds) {
        this.$emit('emitDeleteMultipleRecords', recordsIds)
      },

      confirmDeleteMultipleRecords() {
        if (!this.gridOptions.api.getSelectedRows().length)
          return;
        let recordsIds = this.gridOptions.api.getSelectedRows().map(element => element.id);
        let title = ' هل أنت متأكد من حذف ' + recordsIds.length + '  من العناصر ؟ '
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: title,
          text: `في حال الموافقة, لن تتمكن من التراجع!`,
          accept: this.deleteMultipleRecords,
          acceptText: 'حذف',
          parameters: [recordsIds]
        });
      },

      onRowDragEnd(e) {
        console.log('onRowDragEnd', e);
        console.log(this.rowData.map(i => i.id));
      },
      checkAgGridEvent(event) {
        return this.$listeners && this.$listeners[event];
      },
      isAdmin(){
        return utilities.isAdmin();
      }
    },
    mounted() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      this.gridApi.showLoadingOverlay();
      /* =================================================================
        NOTE:
        Header is not aligned properly in RTL version of agGrid table.
        However, we given fix to this issue. If you want more robust solution please contact them at gitHub
      ================================================================= */
      let allColumnIds = [];
      let columnsWidth = 0;
      this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        columnsWidth += column.getActualWidth()
        allColumnIds.push(column.getColId());
      });
      if (this.$store.state.windowWidth >= columnsWidth)
        this.gridOptions.api.sizeColumnsToFit();

      if (this.$vs.rtl) {
        const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
        header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`
      }
    },
    created() {
      if (!this.rtl) {
        this.gridOptions.columnDefs.reverse();
      }
    }
  }

</script>
