import {httpClient} from "../shared-services/httpClient";
import {environment} from "@/environment";
const URL = environment.apiBaseURL + 'permissions';
export default {

    fetchAllPermissions() {
        return httpClient.get(URL)
            .then(res => res.data.data)
            .catch(error => console.log(error))
    },
    givePermissionToRole( permissionsIds , roleId){
        const url = environment.apiBaseURL + `roles/${roleId}` + '/permissions';
        let permissionsIdsArray ={permissions : permissionsIds };
        return httpClient.post(url,permissionsIdsArray)
            .then(res => res.data.data)
            .catch((error => console.log(error)))
    },
    revokePermission(permissionId , roleId){
        const url = environment.apiBaseURL + `roles/${roleId}` + `/permissions/${permissionId}`;
        return httpClient.delete(url)
            .then(res => res["data"])
            .catch((error => console.log(error)))
    },
    assignPermissionToUser(permissionsIds , userId){
        const url =  environment.apiBaseURL + `users/${userId}` + `/permissions`
        let permissionsIdsArray ={permissions : permissionsIds };
        return httpClient.post(url,permissionsIdsArray)
            .then(res => res.data.data)
            .catch((error => console.log(error)))
    },
    revekePermissionFromUser(permissionId , userId){
        const url =  environment.apiBaseURL + `users/${userId}` + `/permissions/${permissionId}`
        return httpClient.delete(url)
            .then(res => res["data"])
            .catch((error => console.log(error)))
    },
}