<template>
    <div>
        <vs-card>
            <vs-row vs-type="flex" vs-justify="space-between">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                    <h5>
                        <vs-chip color="primary">المعرف : {{ store.id }}</vs-chip>
                    </h5>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                    <h5>
                        <vs-chip color="primary">اسم المحل : {{ store.name }}</vs-chip>
                    </h5>
                </vs-col>
            </vs-row>
        </vs-card>
        <ag-table :gridOptions="gridOptions"
                  :rowData="rowData"
                  @emitAddNewRecord="createRecord()"
                  @emitDeleteMultipleRecords="deleteStoreAdmin($event, null , true)"
        >
        </ag-table>
        <vs-popup title="إضافة مدير لهذا المحل" :active.sync="popupActive">
            <vs-progress v-if="isLoading" indeterminate color="primary"></vs-progress>
            <ValidationObserver v-slot="{handleSubmit}" ref="validationObserver">
                <form>
                    <div class="vx-row mt-5">
                        <div class="vx-col sm:w-1/4 w-full  mb-2">
                            <label>اختر المستخدم</label>
                        </div>
                        <div class="vx-col sm:w-1/2 w-full  mb-2">
                            <v-select label="email" :options="users"
                                      :value="user.email"
                                      v-model="selectedUser"
                                      dir="rtl"/>
                        </div>
                    </div>
                    <div class="vx-row mt-5">
                        <div class="vx-col sm:w-1/2 w-full mb-2">
                            <vs-button :disabled="isLoading" ycolor="success" type="filled" @click.prevent="handleSubmit(onSubmit())">
                                حفظ
                            </vs-button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </vs-popup>
    </div>
</template>

<script>
    import AgTable from '../../../shared/shared-components/ag-grid/AgTable';
    import Loader from "@/app/shared/shared-components/Loader";
    import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell";
    import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
    import vSelect from 'vue-select'

    const storeRepo = RepositoryFactory.get('store');

    export default {
        name: "StoreAdmins",
        components: {
            AgTable,
            Loader,
            vSelect,
        },
        data() {
            return {
                isLoading: true,
                gridOptions: null,
                rowData: [],
                popupActive: false,
                users :[],
                selectedUser:{},
                user :{
                    id : null,
                    email: '',
                },
                store:Object,
            }
        },
        methods: {
            createColDefs() {
                let self = this;
                return [
                    {
                        headerName: 'المعرف',
                        field: 'id',
                        filter: 'agTextColumnFilter',
                        suppressSizeToFit: true,
                        imagePreview: null,
                        width: 250,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                    },
                    {
                        headerName: 'الاسم',
                        field: 'first_name',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'الكنية',
                        field: 'last_name',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'الإيميل',
                        field: 'email',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'رقم الموبايل',
                        field: 'mobile',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'تاريخ الولادة',
                        field: 'birthdate',
                        filter: 'agTextColumnFilter'
                    },
                    {
                        headerName: 'الإجراءات',
                        field: 'id',
                        cellRendererFramework: AgTableBtnCell,
                        width: 250,
                        cellRendererParams: {
                            deleteRecord: function (id, rowIndex) {
                                self.deleteStoreAdmin(id, rowIndex)
                            },
                            actions: ['delete']
                        },

                    },
                ];
            },
            deleteStoreAdmin(ids, index, isMultiple = false) {
                this.isLoading = true;
                if(isMultiple)
                    ids = ids.join(',')
                storeRepo.deleteStoreAdmin(ids,this.$route.params.id).then((response) => {
                    if (response.code === 200) {
                        if(isMultiple) {
                            this.fetchAllStoreAdmins();
                        }
                        else {
                            this.rowData.splice(index, 1);
                            this.rowData = [...this.rowData];
                        }
                    }
                    this.isLoading = false;
                })
            },
            // used to fire the dialog which response to add a new record in the table
            createRecord() {
                this.openPopup();
            },
            openPopup() {
                this.fetchAllAllowStoreAdminsUsers();
                this.$refs.validationObserver.reset();
                this.popupActive = true;
            },
            onSubmit() {
                storeRepo.addStoreAdmin(this.selectedUser.id,this.$route.params.id).then((response) =>{
                    this.handleResponse(response.data);
                    this.rowData.unshift(response.data.data);
                    this.isLoading = false;
                })
            },
            handleResponse() {
                this.popupActive = false;
            },
            fetchAllStoreAdmins() {
                storeRepo.fetchAllStoreAdmins(this.$route.params.id).then((data) => {
                    this.rowData = [];
                data.forEach(item => {
                        this.rowData.push(item.user)
                    });
                    this.isLoading = false;
                });
            },
            fetchAllAllowStoreAdminsUsers(){
                storeRepo.fetchAllAllowStoreAdminsUsers(this.$route.params.id).then((data) => {
                    this.users = data;
                    this.isLoading = false;
                });
            },
            fetchStoreById(){
                storeRepo.fetchStoreById(this.$route.params.id).then((data) => {
                    this.store = data;
                    this.isLoading = false;
                });

            }
        },
        created() {
            // prepare ag grid options
            this.gridOptions = {};
            this.gridOptions.columnDefs = this.createColDefs();
            this.fetchStoreById();
            this.fetchAllStoreAdmins();

        },
        mounted() {
            this.gridApi = this.gridOptions.api;
        }
    }
</script>

<style scoped>

</style>
