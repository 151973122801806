import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.ecommerceApiBaseUrl}subProductVariants`;

export default {
  store(subProductVariant) {
    return httpClient.post(URL, subProductVariant)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  delete(ids) {
    return httpClient.delete(`${URL}/${ids}`)
      .then(res => res.data)
      .catch(error => console.log(error));
  }
}
