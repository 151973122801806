const getters = {
  user: state => {
    return state.user
  },
  isAuthenticated: state => {
    return !!state.user.token
  }
};

export default getters
