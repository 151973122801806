import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = 'general/types';

export default {

  fetchGeneralTypes(type) {
    const url = environment.apiBaseURL + URL + '/' + type;
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error))
  },
}
