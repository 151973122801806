<template>
  <tm-popup :submit="onSubmit" title="تفاصيل المنتج الفرعي" ref="popup" :fullscreen="true">
    <vs-row>
      <vs-card>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" class="mb-5">
          <tm-select
                  class="w-full"
                  :options="products"
                  label="shownName"
                  validation-rules="required"
                  name="المنتج"
                  v-model="product_id"
                  :reduce="name => name.id"
          />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" class="mb-5">
          <tm-input
                  name="الاسم"
                  type="text"
                  placeholder="الاسم"
                  validation-rules="required"
                  v-model="subProduct.name"
                  class="w-full"/>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" class="mb-5">
          <tm-input
                  name="السعر"
                  type="number"
                  placeholder="السعر"
                  validation-rules="required"
                  v-model="subProduct.price"
                  class="w-full"/>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" class="mb-5">
          <tm-input
                  name="الكمية"
                  type="number"
                  placeholder="الكمية"
                  validation-rules="required|min_value:0"
                  v-model="subProduct.qty"
                  class="w-full"/>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" class="mb-5">
          <tm-input
                  name="التفاصيل"
                  type="text"
                  placeholder="التفاصيل"
                  v-model="subProduct.desc"
                  class="w-full"/>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" class="mb-5">
          <tm-select
                  class="w-full"
                  :options="packageSizes"
                  label="shownName"
                  name="حجم التغليف"
                  v-model="subProduct.package_size_id"
                  :reduce="size => size.id"
          />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="start" vs-align="right" vs-w="12">
          <vs-row>
            <vs-card v-for="(keyValue, index ) in subProduct.details" v-bind:key="keyValue.id">
              <vs-row vs-align="center">

                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12">
                  <tm-input
                          name="اسم القيمة"
                          type="text"
                          placeholder="اسم القيمة"
                          validation-rules="required"
                          v-model="keyValue.key"/>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="4" vs-xs="12">
                  <tm-input
                          name="التفاصيل"
                          type="text"
                          placeholder="التفاصيل"
                          validation-rules="required"
                          v-model="keyValue.value"
                          class="w-full"/>
                </vs-col>
                <vs-col v-if="isManyKeyValue" vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12">
                  <vs-button color="danger" type="gradient" icon-pack="feather" icon="icon-x"
                             @click="deleteKeyValue(index)"/>
                </vs-col>
              </vs-row>
            </vs-card>
            <vs-col vs-lg="12" class="mb-6">
              <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-plus" size="small"
                         @click="addKeyValue()">اضافة
              </vs-button>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-card>

      <vs-col vs-type="flex" vs-justify="start" vs-align="right" vs-w="12">
        <vs-checkbox class="inline-flex" v-model="subProduct.allow_upload_image">السماح برفع صورة</vs-checkbox>
      </vs-col>
    </vs-row>
  </tm-popup>
</template>

<script>
  import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
  import TmPopup from "../../../shared/shared-components/TmPopup";
  import TmInput from "../../../shared/shared-components/TmInput";
  import TmSelect from "../../../shared/shared-components/TmSelect";
  import TmEditor from "../../../shared/shared-components/TmEditor";

  const subProductRepository = RepositoryFactory.get("subProduct");
  const productRepository = RepositoryFactory.get("product");
  const packageSizeRepo = RepositoryFactory.get("packageSize");

  export default {
    name: "UpdatePopup",
    data() {
      return {
        products: [{id: '',name: '', shownName: ''}],
        product_id: parseInt(this.productId),
        packageSizes:[],
        isManyKeyValue:false
      }
    },

    props: {
      subProduct: {
        type: Object,
        required: true
      },
      productId: {
        type: String,
        required: true
      }
    },

    methods: {
      onSubmit() {
        this.$refs.popup.loading();
        this.subProduct.product_id = this.product_id;
        this.update();
      },
      update() {
        subProductRepository.update(this.subProduct).then((response) => {
          this.$refs.popup.close();
          this.$emit("updated", response.data);
        });
      },
      open() {
        this.$refs.popup.open();
      },
      addKeyValue() {
        this.isManyKeyValue = true;
        this.subProduct.details.push({
                  key: null,
                  value: null
                }
        )
      },
      deleteKeyValue(index) {
        this.subProduct.details.splice(index, 1)
      },
      getAllProducts() {
        productRepository.getAll().then(response => {
          this.products = response.data;
          this.products.map(p => {
            p.shownName = `${p.category.store.name} - ${p.name}`;
            return p;
          });
        });
      },
      getAllPackageSizes() {
        packageSizeRepo.fetchAllPackageSizes().then((data) => {
          this.packageSizes = data;
          this.packageSizes.map(p => {
            p.shownName = `${p.size}`;
            return p;
          });
        });
      }
    },
    created() {
      this.getAllProducts();
      this.getAllPackageSizes();
    },
    components: {
      TmPopup,
      TmInput,
      TmSelect,
      TmEditor
    },
  }
</script>
