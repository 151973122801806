<template>
    <div>
        <vs-card>
            <vs-row vs-type="flex" vs-justify="space-between">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                    <h5>
                        <vs-chip color="primary">المعرف : {{ user.id }}</vs-chip>
                    </h5>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                    <h5>
                        <vs-chip color="primary"> اسم المستخدم : {{ user.last_name }}</vs-chip>
                    </h5>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                    <h5>
                        <vs-chip color="primary"> الإيميل : {{ user.email }}</vs-chip>
                    </h5>
                </vs-col>
            </vs-row>
        </vs-card>
        <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
                <vs-button :disabled="isLoading" ycolor="success" type="filled" @click.prevent="onSubmit()">
                    حفظ
                </vs-button>
            </div>
            <vx-drag-and-drop-multiple-lists v-if="permissions.length > 0" list1title=" كافةالصلاحيات" list2title="الصلاحيات المراد إضافتها لهذا المستخدم"
                                             :list1="permissions" :list2="addedPermissions"></vx-drag-and-drop-multiple-lists>
        </div>
    </div>
</template>

<script>
    import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
    import Loader from "@/app/shared/shared-components/Loader";

    const permissionRepo =  SharedRepositoryFactory.get('permissionRepository');
    const userRepo = SharedRepositoryFactory.get('userRepository');

    export default {
        name: "UserMain",
        components: {
            Loader,
        },

        data() {
            return {
                user: Object,
                isLoading: true,
                popupActive: false,
                permissions :[],
                addedPermissions:[],
                permission :{
                    id : null,
                    name: '',
                    name_ar :'',
                    desc :''

                },
            }
        },

        methods: {
            onSubmit() {
                const ids = this.addedPermissions.map(item => item.id);
                permissionRepo.assignPermissionToUser(ids,this.$route.params.id).then(() =>{
                    this.fetchAllPermissionsForUser(this.$route.params.id);
                    this.isLoading = false;
                })
            },

            fetchAllPermissions(){
                permissionRepo.fetchAllPermissions().then((data) => {
                    this.permissions = data.filter(item => !this.addedPermissions.find(permission => (permission.name === item.name)));
                });
            },
            fetchAllPermissionsForUser(userId){
                userRepo.fetchUserById(userId).then((response) => {
                            this.addedPermissions = response.permissions;
                            this.user = response;
                    this.fetchAllPermissions();
                    this.isLoading = false;
                });
            }
        },
        created() {
            this.fetchAllPermissionsForUser(this.$route.params.id);
        }
    }
</script>
