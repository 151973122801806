<template>
  <ValidationProvider :name="name" :rules="validationRules" v-slot="{ errors }">
    <vs-input :type="type"
              :label="name"
              :placeholder="placeholder"
              v-model="computedValue"
              class="w-full"
    />
    <span class="text-danger">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
  export default {
    name: "TmInput",
    props: {
      name: {type: String, required: true},
      value: {type: [Number, String]},
      type: {type: String, required: true},
      placeholder: {type: String, required: true},
      validationRules: {type: String, required: true}
    },
    computed: {
      computedValue: {
        get() {
          return this.value
        },
        set(newValue) {
          this.$emit("input", newValue)
        }
      }
    },
  }
</script>
