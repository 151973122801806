<template>
  <div>
    <order-status :isShowAllStatus="false"></order-status>
    <vs-card>
      <vs-row vs-type="flex" vs-justify="space-between" class="mt-5 mb-5">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <h5>
            <vs-chip color="primary">المعرف : {{ order.id }}</vs-chip>
          </h5>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <h5>
            <vs-chip color="primary"> نوع الطلب : {{ order.order_type }}</vs-chip>
          </h5>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <h5>
            <vs-chip color="primary"> الزبون : {{ order.user.first_name }}</vs-chip>
          </h5>
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-justify="space-between" >
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" v-if="order.message">
          <h5>
            <vs-chip color="primary">الملاحظات المرسلة للزبون:</vs-chip>
          </h5>
          <h5>{{ order.message }}</h5>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center"  v-if="order.rejection_reason">
          <h5>
            <vs-chip color="danger"> سبب رفض الطلب </vs-chip>
          </h5>
          <h5>{{ order.rejection_reason }}</h5>
        </vs-col>
      </vs-row>
    </vs-card>

    <ag-table :gridOptions="gridOptions"
              :rowData="rowData"
              :rtl=false
    >
    </ag-table>

    <vs-popup title="معلومات المنتج" :active.sync="popupActiveDetails">
      <form>
        <div class="vx-row mt-2" v-if="orderDetail.uploaded_image_url">
          <img height="350rem" width="550rem" :src="orderDetail.uploaded_image_url" class="m-auto custom-image-size">
        </div>

        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>المعرّف : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ orderDetail.id }}</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <h4>الكمية : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ orderDetail.qty }}</h4>
          </div>
        </div>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>اسم المنتج : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ orderDetail.sub_product.name }}</h4>
          </div>

          <div class="vx-col sm:w-1/4 w-full">
            <h4>سعر المنتج : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ orderDetail.sub_product.price }}</h4>
          </div>
        </div>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>الوصف : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ orderDetail.sub_product.desc }}</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <h4> حالة الطلب : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ orderDetail.arabic_status }}</h4>
          </div>
        </div>
        <div v-if="orderDetail.package_class" class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>فئة التغليف : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ orderDetail.package_class.class }}</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <h4> سعر التغليف : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ orderDetail.price }}</h4>
          </div>
        </div>
        <div class="vx-row mt-10" v-if="orderDetail.user_note">
          <div class="vx-col w-full">
            <h4>ملاحظات الزبون :</h4>
          </div>
          <div class="vx-col w-full">
            <h4>{{ orderDetail.user_note }}</h4>
          </div>
        </div>
        <div class="vx-row mt-10" v-if="orderDetail.message">
          <div class="vx-col w-full">
            <h4>ملاحظات مدير المحل : </h4>
          </div>
          <div class="vx-col w-full">
            <h4>{{ orderDetail.message }}</h4>
          </div>
        </div>

        <div class="vx-row mt-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-button ycolor="success" type="filled" @click="popupActiveDetails=false">حسناً
            </vs-button>
          </div>
        </div>

      </form>

    </vs-popup>
    <vs-popup :title="this.statusAction" :active.sync="popupActive">
      <vs-progress v-if="isLoading" indeterminate color="primary"></vs-progress>
      <ValidationObserver v-slot="{handleSubmit}" ref="validationObserver">
        <form>
          <div class="vx-row mt-5 justify-center">
            <h3 > سيتم تغيير حالة الطلب من</h3>
            <h3 :style="{'color':fromStatusColor }" class="vx-col">{{fromStatus}}</h3>
            <h3> إلى </h3>
            <h3 :style="{'color':toStatusColor }" class="vx-col">{{toStatus}}</h3>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col w-full mb-2">
              <label>الرسالة</label>
              <vs-input placeholder="الرسالة" v-model="message" class="w-full"/>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button :disabled="isLoading" ycolor="success" type="filled" @click.prevent="handleSubmit(onSubmit())">
                إرسال
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>

  </div>
</template>

<script>
  import AgTableImageCell from '../../../shared/shared-components/ag-grid/AgTableImageCell'
  import AgTable from '../../../shared/shared-components/ag-grid/AgTable';
  import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell";
  import AgIcon from "@/app/ecommerce/ecommerce-components/AgIcon";
  import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
  import vSelect from 'vue-select'
  import imageUploader from '../../../shared/shared-components/ImageUploader'
  import 'quill/dist/quill.snow.css'
  import {quillEditor} from 'vue-quill-editor'
  import utilities from "@/app/shared/utilities";
  import OrderStatus from '../../ecommerce-components/OrderStatus'

  const order = RepositoryFactory.get('order');

  export default {
    name: "OrderDetail",
    components: {
      AgTable,
      vSelect,
      imageUploader,
      quillEditor,
      OrderStatus
    },
    data() {
      return {
        isLoading: true,
        gridOptions: null,
        rowData: [],
        order: {
          id: '',
          order_type: '',
          user: {first_name: ''}
        },
        popupActiveDetails: false,
        popupActive:false,
        imageRequired: true,
        imagePreview: null,
        dialogTitle: String,
        statusAction:'',
        newStatus:'',
        fromStatus:'',
        fromStatusColor :'',
        toStatus:'',
        toStatusColor:'',
        orderDetail: {
          id: null,
          sub_product: {name: '', price: '',desc:''},
          qty: '',
          user_note: '',
          status:'',
          arabic_status:'',
          uploaded_image_url: '',
          package_class:{
            class:'',
            price:''
          },
        },
        orderDetailId :'',
        message :''
      }
    },
    watch: {
      "$route.params.id"() {
        this.fetchAllOrderDetails();
      },
    },
    methods: {
      createColDefs() {
        let self = this;
        return [
          {
            headerName: 'معرف الطلب',
            field: 'order_id',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'المعرف',
            field: 'id',
            filter: 'agTextColumnFilter',
            suppressSizeToFit: true,
            imagePreview: null,
            headerCheckboxSelection: true,
            checkboxSelection: true,
          },
          {
            headerName: 'خيارات المنتج المطلوب',
            valueGetter:
                    function getObject(params) {
                      return params
                    },
            cellRendererFramework: AgIcon,
            cellRendererParams: {
              icon: 'ChevronsLeftIcon',
              secondIcon :'XCircleIcon',
              click(valueGetter)
              {
                self.changeOrderDetailsStatus(valueGetter)
              },
              clickSecondIcon(valueGetter) {
                self.changeOrderDetailsStatus(valueGetter,true)
              }
            }
          },
          {
            headerName: 'اسم المنتج',
            field: 'sub_product.name',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'حالة الطلب',
            field: 'arabic_status',
            filter: 'agTextColumnFilter',
            cellStyle: self.changeCellColor,
          },
          {
            headerName: 'وصف المنتج',
            field: 'sub_product.desc',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'الكمية',
            field: 'qty',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'سعر المنتج',
            field: 'sub_product.price',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'الصورة المرفقة',
            field: 'uploaded_image_url',
            cellRendererFramework: AgTableImageCell
          },
          {
            headerName: 'فئة التغليف',
            field: 'package_class.class',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'سعر التغليف',
            field: 'package_class.price',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'ملاحظات الزبون',
            field: 'user_note',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'ملاحظات مدير المحل',
            field: 'message',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'الإجراءات',
            field: 'id',
            cellRendererFramework: AgTableBtnCell,
            cellRendererParams: {
              viewRecordDetails: function (record) {
                self.viewRecordDetails(record)
              },
              actions: ['view']
            }
          },
        ];
      },
      viewRecordDetails(record) {
        this.popupActiveDetails = true;
        this.orderDetail = record;
      },
      // used to rerender updated row
      rerender() {
        this.gridApi.setRowData(this.rowData)
      },
      openPopup() {
        this.$refs.validationObserver.reset();
        this.popupActive = true;
      },
      changeCellColor(params) {
        return {'color': utilities.setOrderStatusColor(params.node.data.status)};
      },
      changeOrderDetailsStatus(record,isRejected = false){
        this.orderDetailId = record.data.id;
        let statusObject = utilities.changeOrderStatus(record.data.status,isRejected);
        this.statusAction = statusObject.statusAction;
        this.newStatus = statusObject.newStatus;
        this.fromStatus = statusObject.fromStatus;
        this.toStatus = statusObject.toStatus;
        this.fromStatusColor = statusObject.fromStatusColor;
        this.toStatusColor = statusObject.toStatusColor;
        if(this.newStatus != null) {
          this.openPopup();
        }
      },
      onSubmit(){
        this.isLoading = true;
        order.changeOrderDetailsStatus(this.orderDetailId,this.newStatus,this.message).then((response) => {
          let index = this.rowData.map((m) => {
            return m.id;
          }).indexOf(response.data.data.id);
          this.rowData[index] = response.data.data;
          this.rerender();
          this.popupActive = false;
          this.isLoading = false;
        });
      },

      // get order details from the order
      fetchAllOrderDetails() {
        order.fetchOrderById(this.$route.params.id).then((data) => {
          this.order = data;
          this.rowData = data.order_details;
          this.isLoading = false;
        });
      },
    },
    created() {
      // prepare ag grid options
      this.gridOptions = {};
      this.gridOptions.columnDefs = this.createColDefs();
      this.gridOptions.defaultColDef ={resizable: true,};
      this.fetchAllOrderDetails();

    },
    mounted() {
      this.gridApi = this.gridOptions.api;
    }
  }
</script>

<style scoped>

</style>
