<template>
    <div id="ag-grid-demo">
        <vx-card>
            <div class="flex flex-wrap justify-between items-center">

                <!-- ITEMS PER PAGE -->
                <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
                    <vs-dropdown class="cursor-pointer" vs-trigger-click>
                        <div
                                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                            <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ totalRows - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : totalRows }} of {{ totalRows }}</span>
                            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
                        </div>
                        <vs-dropdown-menu>
                            <vs-dropdown-item @click="getPerPage(20)">
                                <span>20</span>
                            </vs-dropdown-item>
                            <vs-dropdown-item @click="getPerPage(50)">
                                <span>50</span>
                            </vs-dropdown-item>
                            <vs-dropdown-item @click="getPerPage(100)">
                                <span>100</span>
                            </vs-dropdown-item>
                            <vs-dropdown-item @click="getPerPage(150)">
                                <span>150</span>
                            </vs-dropdown-item>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                </div>

                <!-- TABLE ACTION COL-2: EXPORT AS CSV -->
                <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
                    <!-- ACTION - DROPDOWN -->
                    <vs-dropdown class="cursor-pointer" vs-trigger-click>

                        <div
                                class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                            <span class="mr-2 leading-none">خيارات</span>
                            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
                        </div>

                        <vs-dropdown-menu>

                            <vs-dropdown-item @click="addNewRecord()" v-show="checkAgGridEvent('emitAddNewRecord')">
                                <span class="flex items-center">
                                  <feather-icon class="mr-2" icon="PlusIcon" svgClasses="h-4 w-4"/>
                                  <span>إضافة</span>
                                </span>
                            </vs-dropdown-item>

                            <vs-dropdown-item>
                                <span @click="gridApi.exportDataAsCsv()" class="flex items-center">
                                  <feather-icon class="mr-2" icon="SaveIcon" svgClasses="h-4 w-4"/>
                                  <span>تصدير بصيغة CSV</span>
                                </span>
                            </vs-dropdown-item>

                            <vs-dropdown-item v-show="checkAgGridEvent('emitDeleteMultipleRecords')&& isAdmin()">
                                <span @click="confirmDeleteMultipleRecords()" class="flex items-center">
                                  <feather-icon class="mr-2" icon="Trash2Icon" svgClasses="h-4 w-4"/>
                                  <span>حذف متعدد</span>
                                </span>
                            </vs-dropdown-item>
                            <vs-dropdown-item v-show="checkAgGridEvent('emitShowDetails')">
                                <span @click="showDetails()" class="flex items-center">
                                  <feather-icon class="mr-2" icon="ListIcon" svgClasses="h-4 w-4"/>
                                  <span>عرض التفاصيل</span>
                                </span>
                            </vs-dropdown-item>
                            <vs-dropdown-item @click="showCompletedRecords()"
                                              v-show="showCompleted&&checkAgGridEvent('emitShowCompletedRecords')">
                                <span class="flex items-center">
                                     <feather-icon class="mr-2" icon="KeyIcon" svgClasses="h-4 w-4"/>
                                  <span>عرض الطلبات المنتهية</span>
                                </span>
                            </vs-dropdown-item>
                            <vs-dropdown-item @click="showCurrentRecords()" v-show="!showCompleted">
                                <span class="flex items-center">
                                  <feather-icon class="mr-2" icon="ActivityIcon" svgClasses="h-4 w-4"/>
                                  <span>عرض الطلبات الفعالة</span>
                                </span>
                            </vs-dropdown-item>

                        </vs-dropdown-menu>
                    </vs-dropdown>
                </div>
            </div>
          <ag-grid-vue
            :animate-rows="true"
            :cache-block-size="cacheBlockSize"
            :default-col-def="defaultColDef"
            :enable-rtl="rtl"
            :floating-filter="true"
            :grid-options="gridOptions"
            :pagination="true"
            :pagination-page-size="paginationPageSize"
            :row-drag-managed="false"
            :row-model-type="rowModelType"
            :server-side-store-type="serverSideStoreType"
            :suppress-column-virtualisation="true"
            :suppress-pagination-panel="true"
            @grid-ready="onGridReady"
            class="ag-theme-material ag-grid-table"
            id="myGrid"
            ref="agGridTable"
            row-selection="multiple"
            style="width: 100%;"
          />
            <vs-pagination
                    :max="maxPageNumbers"
                    :total="totalPages"
                    v-model="currentPage"/>
        </vx-card>
    </div>
</template>
<script>
    import {AgGridVue} from 'ag-grid-vue';
    import utilities from "@/app/shared/utilities";
    import 'ag-grid-enterprise';
    import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

    export default {
        data() {
            return {
                setPaginationPageSize: false,
                maxPageNumbers: 7,
                showCompleted: true,
                page: '',
                query: '',
                perPage: '',
                gridApi: null,
                gridColumnApi: null,
              defaultColDef: {
                minWidth: 120,
                sortable: true,
                editable: false,
                resizable: true,
                suppressMenu: true,
                filterParams: {
                  buttons: ['apply', 'reset']
                }
              },
                totalRows: '',
                dataSource: '',
                rowModelType: null,
                serverSideStoreType: null,
                cacheBlockSize: null,
                data: [],
                filterKeys: {},
                filterValues: {}
            }
        },
        props:
            {
                gridOptions: {
                    type: Object,
                    default: null
                },
                dataSourceApi: {
                    type: Function,
                    default: null
                },
              rtl: {
                type: Boolean,
                default: true
              }
            },
        components: {
            'ag-grid-vue': AgGridVue,
        },
        watch: {
            '$store.state.windowWidth'(val) {
                if (val <= 576) {
                    this.maxPageNumbers = 4;
                    this.gridOptions.columnApi.setColumnPinned('email', null)
                } else this.gridOptions.columnApi.setColumnPinned('email', 'left')
            }
        },
        computed: {
            paginationPageSize() {
                if (this.gridApi) return this.gridApi.paginationGetPageSize();
                else return 50
            },
            totalPages() {
                if (this.gridApi) return this.gridApi.paginationGetTotalPages();
                else return 0
            },
            currentPage: {
                get() {
                    if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                    else return 1
                },
                set(val) {
                    this.gridApi.paginationGoToPage(val - 1)
                }
            }
        },
        created() {
            this.rowModelType = 'serverSide';
            this.serverSideStoreType = 'partial';
            this.cacheBlockSize = this.paginationPageSize;
        },
        methods: {
            getPerPage(perPage) {
                this.gridApi.paginationSetPageSize(perPage);
                this.perPage = perPage;
                this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', perPage);
                this.gridApi.setServerSideDatasource(this.datasource)
            },
            addNewRecord() {
                this.$emit('emitAddNewRecord')
            },
            showCurrentRecords() {
                this.showCompleted = true;
                this.gridApi.refreshServerSideStore({purge: true});
            },
            showCompletedRecords() {
                this.showCompleted = false;
                this.$emit('emitShowCompletedRecords')
            },
            showDetails() {
                this.$emit('emitShowDetails')
            },
            deleteMultipleRecords(recordsIds) {
                this.$emit('emitDeleteMultipleRecords', recordsIds)
            },
            confirmDeleteMultipleRecords() {
                if (!this.gridOptions.api.getSelectedRows().length)
                    return;
                let recordsIds = this.gridOptions.api.getSelectedRows().map(element => element.id);
                let title = ' هل أنت متأكد من حذف ' + recordsIds.length + '  من العناصر ؟ ';
                this.$vs.dialog({
                    type: 'confirm',
                    color: 'danger',
                    title: title,
                    text: `في حال الموافقة, لن تتمكن من التراجع!`,
                    accept: this.deleteMultipleRecords,
                    acceptText: 'حذف',
                    parameters: [recordsIds]
                });
            },
            checkAgGridEvent(event) {
                return this.$listeners && this.$listeners[event];
            },
            isAdmin() {
                return utilities.isAdmin();
            },
            onGridReady(params) {
                const that = this;
                that.datasource = {
                    getRows: function (params) {
                            that.query = utilities.getQueryParameters(params, that.paginationPageSize, that.currentPage);
                        that.dataSourceApi(that.query).then((response) => {
                            if (response.code === 200) {
                                that.totalRows = response.data.total;
                                params.successCallback(response.data.entities, response.data.total)
                            }
                        })
                    }
                };
                params.api.setServerSideDatasource(that.datasource);

            },
        },
        mounted() {
          this.gridApi = this.gridOptions.api;
          this.gridColumnApi = this.gridOptions.columnApi;
          let allColumnIds = [];
          let columnsWidth = 0;
          this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.getColId());
            columnsWidth += column.getActualWidth()
          });

          if (this.$store.state.windowWidth >= columnsWidth)
            this.gridOptions.api.sizeColumnsToFit();

          if (this.$vs.rtl) {
            const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container');
            header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`
          }
        },
      created() {
        if (!this.rtl) {
          this.gridOptions.columnDefs.reverse();
        }
        this.rowModelType = 'serverSide';
        this.serverSideStoreType = 'partial';
        this.cacheBlockSize = this.paginationPageSize;
      },
    }
</script>                 
