import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.ecommerceApiBaseUrl}order`;

export default {

  fetchAllOrders(ordersType,isCompleted) {
    const url = isCompleted? environment.ecommerceApiBaseUrl+ ordersType +'/completed_orders': environment.ecommerceApiBaseUrl+ ordersType;
      return httpClient.get(url)
          .then(res => res.data['data'])
          .catch(error => console.log(error))
  },
  fetchOrderById(orderId){
    const url = `${URL}/${orderId}` ;
      return httpClient.get(url)
          .then(res => res.data['data'])
          .catch(error => console.log(error))
  },
  /*deleteOrder(orderId, isImage = false) {
    let url = URL + '/' + orderId;
    url = isImage ? url + '/images' : url;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error))
  },*/
  changeOrderStatus(orderId,status,message){
    let url = URL +`/${orderId}/status/${status}`;
    let method = status === "Rejected" ? {  _method :"PUT",reason: message}:{  _method :"PUT", message: message};
    return httpClient.post(url,method)
        .then(res => res)
        .catch(error => console.log(error))
  },
  changeOrderDetailsStatus(orderId,status,message){
    let url = URL +`Detail/${orderId}/status/${status}`;
    let method = status === "Rejected" ? {  _method :"PUT",reason: message}:{  _method :"PUT", message: message};
    return httpClient.post(url, method)
        .then(res => res)
        .catch(error => console.log(error))
  }

}
