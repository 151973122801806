<template>
  <div>

    <ag-table
      :gridOptions="gridOptions"
      :rowData="rowData"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteTag($event, null, true)"
      @emitShowArchivedRecords="fetchAllTags(true)"
      @emitShowCurrentRecords="fetchAllTags()"
    >
    </ag-table>

    <vs-popup title="تفاصيل التاغ" :active.sync="popupActive">
      <vs-progress v-if="isLoading" indeterminate color="primary"></vs-progress>
      <ValidationObserver v-slot="{ handleSubmit }" ref="validationObserver">
        <form>
          <div class="vx-row">
            <div class="vx-col w-full text-center">
              <h4 class="my-5">الصورة</h4>
              <image-uploader
                @fileUploaded="fileUploaded"
                :imagePreview="imagePreview"
                ref="imageUploader"
              />
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <ValidationProvider
                name="اسم التاغ"
                rules="required"
                v-slot="{ errors }"
              >
                <label>الاسم</label>
                <vs-input
                  placeholder="الاسم"
                  v-model="tag.name"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button
                :disabled="isLoading"
                ycolor="success"
                type="filled"
                @click.prevent="handleSubmit(onSubmit)"
              >
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>

    <vs-popup title="تفاصيل التاغ" :active.sync="popupActiveDetails">
      <form>
        <div class="vx-row">
          <img :src="tag.image_url" class="m-auto custom-image-size">
        </div>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>المعرّف : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ tag.id }}</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <h4>التاغ : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ tag.name }}</h4>
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-button ycolor="success" type="filled" @click="popupActiveDetails=false">حسناً
            </vs-button>
          </div>
        </div>

      </form>

    </vs-popup>

  </div>
</template>

<script>
  import AgTable from "../shared-components/ag-grid/AgTable";
  import AgTableBtnCell from "../shared-components/ag-grid/AgTableBtnCell.vue";
  import utilities from "@/app/shared/utilities";
  import vSelect from "vue-select";
  import Loader from "@/app/shared/shared-components/Loader";
  import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
  import AgTableImageCell from '../../shared/shared-components/ag-grid/AgTableImageCell';
  import imageUploader from '../../shared/shared-components/ImageUploader'
  import 'quill/dist/quill.snow.css'
  import {quillEditor} from 'vue-quill-editor'


  const tagsRepo = SharedRepositoryFactory.get("tag");
  const sharedRepo = SharedRepositoryFactory.get("generalTypes");

  export default {
    name: "TagsMain",
    components: {
      AgTable,
      vSelect,
      imageUploader,
      Loader,
      quillEditor
    },
    data() {
      return {
        gridOptions: null,
        imagePreview: null,
        popupActiveDetails: false,
        rowData: [],
        tag: {
          id: null,
          name: '',
          type: 'Ecommerce',
          image_url: ''
        },
        popupActive: false,
        isLoading: true,
        filter: ''
      };
    },
    methods: {
      createColDefs() {
        let self = this;
        return [
          {
            headerName: "المعرف",
            field: "id",
            filter: true,
            suppressSizeToFit: true,
            width: 225,
            imagePreview: null,
            headerCheckboxSelection: true,
            checkboxSelection: true,
          },
          {
            headerName: "التاغ",
            field: "name",
            filter: true,
          },
          {
            headerName: "الصورة",
            field: 'image_url',
            cellRendererFramework: AgTableImageCell
          },
          {
            headerName: "الإجراءات",
            field: "id",
            cellRendererFramework: AgTableBtnCell,
            cellRendererParams: {
              deleteRecord: function (id ,rowIndex) {
                self.deleteTag(id ,rowIndex);
              },
              editRecord: function (tag) {
                self.editRecord(tag);
              },
              viewRecordDetails: function (record) {
                self.viewRecordDetails(record)
              },
              actions: ["view", "edit", "delete"],
            },
          },
        ];
      },

      deleteTag(ids, index, isMultiple = false) {
        this.isLoading = true;
        if (isMultiple) ids = ids.join(",");
        tagsRepo.deleteTag(ids).then((response) => {
          if (response.code === 200) {
            if (isMultiple) this.fetchAllTags();
            else {
              this.rowData.splice(index, 1);
              this.rowData = [...this.rowData];
            }
          }
          this.isLoading = false;
        });
      },

      // used to fire the dialog which response to add a new record in the table
      createRecord() {
        utilities.initObjectMembers(this.tag);
        this.$refs.imageUploader.clearImage();
        this.imagePreview = null;
        this.openPopup();
      },

      // used to fire the dialog which response to edit current record in the table
      editRecord(tag) {
        Object.assign(this.tag, tag);
        this.imagePreview = this.tag.image_url;
        this.openPopup();

      },

      viewRecordDetails(record) {
        this.popupActiveDetails = true;
        this.tag = record;
      },

      openPopup() {
        this.$refs.validationObserver.reset();
        this.popupActive = true;
      },
      fileUploaded(file) {
        this.tag.image_url = file;
      },

      onSubmit() {
        this.isLoading = true;
        if (typeof this.tag.image_url == "string")
          delete this.tag.image_url;
        if (this.tag.id !== null) {
          this.updateTag(this.tag);
        } else {
          this.storeTag(this.tag);
        }
      },

      storeTag(tag) {
        tagsRepo.storeTag(this.$objectToFD(tag)).then((response) => {
          this.rowData.unshift(response.data.data);
          this.handleResponse(response.data);
          this.isLoading = false;
        });
      },

      updateTag(tag) {
        tagsRepo.updateTag(this.$objectToFD(tag), tag.id).then((response) => {
          let index = this.rowData
            .map((tag) => {
              return tag.id;
            })
            .indexOf(response.data.data.id);
          this.rowData[index] = response.data.data;
          this.rerender();
          this.handleResponse(response.data);
          this.isLoading = false;
        });
      },

      handleResponse() {
        this.popupActive = false;
      },

      // used to rerender updated row
      rerender() {
        this.gridApi.setRowData(this.rowData);
      },

      fetchAllTags() {
        tagsRepo.fetchAllTags().then((response) => {
          this.rowData = response;
          this.isLoading = false;
        });
      }
    },
    created() {
      // prepare ag grid options
      this.gridOptions = {};
      this.gridOptions.columnDefs = this.createColDefs();
      this.fetchAllTags();
    },
    mounted() {
      this.gridApi = this.gridOptions.api;
    },
  };
</script>
