import Login from "./shared-views/Login";
import tagsMain from "./shared-views/TagsMain";
import UsersMain from "./shared-views/DashboardUsersMain";
import RolesMain from "./shared-views/RolesMain";
import Permissions from "./shared-views/Permissions";
import UsersRoles from "./shared-views/UserRoles";
import UsersPermissions from "./shared-views/UserPermissions";
import DashboardUsersMain from "./shared-views/DashboardUsersMain";
import EcommerceUsersMain from "./shared-views/EcommerceUsersMain";
import Slider from "@/app/shared/shared-views/Slider";

const sharedRoutes = [
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/",
    component: () => import("../../layouts/main/Main.vue"),
    children: [

      {
        path: "/users/:key",
        component: DashboardUsersMain,
        meta: {
          breadcrumb: [
            {title: "Home", url: "/"},
            {title: "جدول المستخدمين", active: true}
          ],
          pageTitle: "المستخدمون"
        }
      },
      {
        path: "/ecommerce/users",
        component: EcommerceUsersMain,
        meta: {
          breadcrumb: [
            {title: "Home", url: "/"},
            {title: "جدول حسابات الزبائن", active: true}
          ],
          pageTitle: "حسابات الزبائن"
        }
      },
      {
        path: "/users/:key",
        component: DashboardUsersMain,
        meta: {
          breadcrumb: [
            {title: "Home", url: "/"},
            {title: "جدول المستخدمين", active: true}
          ],
          pageTitle: "المستخدمون"
        }
      },
      {
        path: "/ecommerce/users",
        component: EcommerceUsersMain,
        meta: {
          breadcrumb: [
            {title: "Home", url: "/"},
            {title: "جدول حسابات الزبائن", active: true}
          ],
          pageTitle: "حسابات الزبائن"
        }
      },
      {
        path: "/sliders",
        component: Slider,
        meta: {
          breadcrumb: [
            { title: "Home", url: "/" },
            { title: "جدول السلايدرات", active: true }
          ],
          pageTitle: "السلايدرات"
        }
      },
      {
        path: "/tags",
        component: tagsMain,
        meta: {
          breadcrumb: [
            { title: "Home", url: "/" },
            { title: "جدول التاغات", active: true }
          ],
          pageTitle: "التاغات"
        }
      },
      {
        path: "/users",
        component: UsersMain,
        meta: {
          breadcrumb: [
            { title: "Home", url: "/" },
            { title: "المستخدمون", active: true }
          ],
          pageTitle: "المستخدمون"
        }
      },
      {
        path: "/roles",
        component: RolesMain,
        meta: {
          breadcrumb: [
            { title: "Home", url: "/" },
            { title: "جدول الأدوار", active: true }
          ],
          pageTitle: "الأدوار"
        }
      },
      {
        path: '/roles/:id/permissions',
        component: Permissions,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            { title: "الأدوار", url: '/roles' },
            { title: "صلاحيات الدور", active: true }
          ],
          pageTitle: 'صلاحيات الدور'
        }
      },
      {
        path: '/users/:id/roles',
        component: UsersRoles ,
        meta: {
          breadcrumb: [
            { title: "Home", url: "/" },
            { title: "جدول المستخدمين", url: "/users" },
            { title: "أدوار المستخدم", active: true }
          ],
          pageTitle: 'أدوار المستخدم'
        }
      },
      {
        path: '/users/:id/permissions',
        component: UsersPermissions ,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            { title: "جدول المستخدمين", url: "/users" },
            { title: "صلاحيات المستخدم", active: true }
          ],
          pageTitle: 'صلاحيات المستخدم'
        }
      }

    ]
  }
];

export default sharedRoutes;
