<template>
  <div>
    <ag-table :gridOptions="gridOptions"
              :rowData="rowData"
              @emitAddNewRecord="createRecord()"
              @emitDeleteMultipleRecords="deleteStore($event, null , true)"
    >
    </ag-table>
    <vs-popup title="تفاصيل المحل" :active.sync="popupActive">
      <vs-progress v-if="isLoading" indeterminate color="primary"></vs-progress>
      <ValidationObserver v-slot="{handleSubmit}" ref="validationObserver">
        <form>
          <!-- Logo -->
          <div class="vx-row">
            <div class="vx-col  w-full text-center">
              <h4 class="my-5">الشعار</h4>
              <image-uploader @fileUploaded="fileUploaded"
                              :imagePreview="imagePreview"
                              :isImageRequired="imageRequired"
                              ref="imageUploader"/>
            </div>
          </div>
          <div class="vx-row ">
            <div class="vx-col sm:w-1/2 w-full">
              <ValidationProvider name="الاسم" rules="required" v-slot="{ errors }">
                <label for="">الاسم</label>
                <vs-input placeholder="الاسم" name="name"
                          v-model="store.name"
                          class="w-full"
                ></vs-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <label for="">المتجر</label>
              <ValidationProvider name="المتجر" :rules="'required'" v-slot="{ errors }">
                <v-select label="name" :options="markets"
                          @input="selectedMarket"
                          v-model="store.market"
                          :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vs-row">
            <div class="vx-col w-full">
              <ValidationProvider name="الشرح" rules="required" v-slot="{ errors }">
                <label for="">شرح</label>
                <quill-editor v-model="store.desc"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button :disabled="isLoading" ycolor="success" type="filled" @click.prevent="handleSubmit(onSubmit)">
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>

    <vs-popup title="تفاصيل المحل" :active.sync="popupActiveDetails">
      <form>
        <div class="vx-row">
          <img :src="store.cover_url" class="m-auto custom-image-size">
        </div>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>المعرّف : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ store.id }}</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <h4>الاسم : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ store.name }}</h4>
          </div>
        </div>

        <div class="vx-row my-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>التفاصيل : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4 v-html="store.desc"></h4>
          </div>
        </div>

        <div class="vx-row mt-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-button ycolor="success" type="filled" @click="popupActiveDetails=false">حسناً
            </vs-button>
          </div>
        </div>

      </form>

    </vs-popup>

  </div>
</template>

<script>
import AgTable from '../../../shared/shared-components/ag-grid/AgTable';
import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell";
import AgTableImageCell from '../../../shared/shared-components/ag-grid/AgTableImageCell'
import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
import AgButton from "../../../shared/shared-components/ag-grid/AgButton";
import vSelect from 'vue-select'
import imageUploader from '../../../shared/shared-components/ImageUploader'
import utilities from '../../../shared/utilities/index'
import 'quill/dist/quill.snow.css'
import {quillEditor} from 'vue-quill-editor'

const storeRepo = RepositoryFactory.get('store');
const marketRepo = RepositoryFactory.get('market');

export default {
  name: "Store",
  components: {
    AgTable,
    vSelect,
    imageUploader,
    quillEditor
  },
  data() {
    return {
      isLoading: true,
      gridOptions: null,
      rowData: [],
      popupActiveDetails: false,
      popupActive: false,
      imageRequired: true,
      imagePreview: null,
      dialogTitle: String,
      selectedMarketCheck: [v => !!v || 'Name is required'],
      markets: [{id: '', name: ''}],
      store: {
        id: null,
        name: '',
        cover_url: '',
        market: null,
        market_id: '',
        desc: '',
        hidden:false
      }
    }
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          imagePreview: null,
          width: 225,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: 'الاسم',
          field: 'name',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'اسم المتجر',
          field: 'market.name',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الشعار',
          field: 'cover_url',
          cellRendererFramework: AgTableImageCell
        },
        {
          headerName: 'مدراء المحل',
          cellRendererFramework: AgButton,
          field: 'id',
          filter: false,
          cellRendererParams: {
            size: 'small',
            color: 'primary',
            name: 'مدراء المحل',
            type: 'gradient',
            click(id) {
              self.rowClicked(id)
            }
          }
        },
      /*  {
          headerName: 'عرض أو إخفاءالمحل',
          cellRendererFramework: AgButton,
          field: 'hidden',
          valueGetter:
                  function getObject(params) {
                    return params
                  },
          filter: false,
          cellRendererParams : params => {
            return {
              size: 'small',
              color: 'primary',
              name: `${params.value.data.hidden ? 'عرض' : 'إخفاء'}`,
              click(valueGetter) {
                self.hiddenStore(valueGetter.data.id)
              }
            }
          }
        },*/
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id,rowIndex) {
              self.deleteStore(id,rowIndex)
            },
            editRecord: function (market) {
              self.editRecord(market)
            },
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record)
            },
            actions: ['view', 'edit', 'delete']
          },

        },
      ];
    },
      rowClicked(id) {
          this.$router.push(`/ecommerce/storeAdmins/${id}`)
      },
    deleteStore(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',')
      storeRepo.deleteStore(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.fetchAllStores();
          else {
            this.rowData.splice(index, 1);
            this.rerender();
          }
        }
        this.isLoading = false;
      })
    },
    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.store = record;
    },
   /* hiddenStore(id){
      storeRepo.hiddenStore(id).then((store) => {
        let index = this.rowData.findIndex(p => p.id === store.id);
        this.rowData[index] = store;
        this.gridApi.setRowData(this.rowData)
        this.isLoading = false;
      });
    },*/
    fileUploaded(file) {
      this.store.cover_url = file;
    },
    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData)
    },
    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },
    // used to fire the dialog which response to add a new record in the table
    createRecord() {
      utilities.initObjectMembers(this.store);
      this.$refs.imageUploader.clearImage();
      this.imagePreview = null;
      this.dialogTitle = 'اضافة متجر';
      this.openPopup();
    },

    // used to fire the dialog which response to edit current record in the table
    editRecord(market) {
      this.$refs.imageUploader.clearImage();
      this.dialogTitle = 'تعديل متجر';
      Object.assign(this.store, market);
      this.imagePreview = this.store.cover_url;
      this.openPopup();
    },
    onSubmit() {
      this.isLoading = true;
      if (typeof this.store.cover_url == "string")
        delete this.store.cover_url;
      if (this.store.id !== null) {
        this.updateStore(this.store);
      } else {
        this.storeStore(this.store);
      }
    },
    selectedMarket(market) {
      this.store.market_id = market.id;
    },
    storeStore(store) {
      storeRepo.storeStore(this.$objectToFD(store)).then((response) => {
        this.rowData.unshift(response.data.data);
        this.popupActive = false;
        this.isLoading = false;
      });
    },
    updateStore(market) {
      market.market_id = this.store.market.id
      storeRepo.updateStore(this.$objectToFD(market), market.id).then((response) => {
        let index = this.rowData.map((m) => {
          return m.id;
        }).indexOf(response.data.data.id);
        this.rowData[index] = response.data.data;
        this.rerender();
        this.popupActive = false;
        this.isLoading = false;
      });
    },
    fetchAllStores() {
      storeRepo.fetchAllStores().then((data) => {
        this.rowData = data;
        this.isLoading = false;
      });
      marketRepo.fetchAllMarkets().then(data => {
        this.markets = data;
      })
    }
  },
  created() {
    // prepare ag grid options
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllStores();

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
}
</script>

<style scoped>

</style>
