<template>
  <div>

    <ag-table :gridOptions="gridOptions" :rowData="rowData"
              :rtl=false
              @emitAddNewRecord="createRecord()"
              @emitDeleteMultipleRecords="deleteUser($event, null , true)"/>
    <vs-popup :active.sync="popupActive" :title="this.dialogTitle">
      <vs-progress color="primary" indeterminate v-if="isLoading"></vs-progress>
      <ValidationObserver ref="validationObserver" v-slot="{handleSubmit}">
        <form @keydown.enter.prevent="handleSubmit(onSubmit)">
          <div class="vx-row">
            <div class="vx-col  w-full text-center">
              <h4 class="my-5">الصورة الشخصية</h4>
              <image-uploader :imagePreview="imagePreview"
                              :isImageRequired="imageRequired"
                              @fileUploaded="fileUploaded"
                              ref="imageUploader"/>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>الاسم</label>
              <ValidationProvider :rules="'required'" name="اسم المستخدم" v-slot="{ errors }">
                <vs-input class="w-full" placeholder="اسم المستخدم" v-model="user.first_name"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>الكنية</label>
              <ValidationProvider :rules="'required'" name="الكنية" v-slot="{ errors }">
                <vs-input class="w-full" placeholder="الكنية" v-model="user.last_name"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>الجنس</label>
              <ValidationProvider :rules="'required'" name="الجنس" v-slot="{ errors }">
                <v-select
                  :options="genderOptions" :reduce="name => name.value" label="name"
                  v-model="user.gender"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <ValidationProvider name="رقم الموبايل" rules="required|length:10" v-slot="{ errors }">
                <label>رقم الموبايل</label>
                <vs-input class="w-full" dir="ltr" placeholder="رقم الموبايل" type="number"
                          v-model="user.mobile"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>الإيميل</label>
              <ValidationProvider :rules="'required'" name="الإيميل" v-slot="{ errors }">
                <vs-input class="w-full" placeholder="الإيميل" v-model="user.email"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>كلمة السر</label>
              <ValidationProvider :rules="'required'" name="كلمة السر" v-slot="{ errors }">
                <vs-input class="w-full" placeholder="كلمة السر" type="password" v-model="user.password"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>تأكيد كلمة السر</label>
              <ValidationProvider :rules="'required'" name="تأكيد كلمة السر" v-slot="{ errors }">
                <vs-input class="w-full" placeholder="تأكيد كلمة السر" type="password"
                          v-model="user.password_confirmation"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button :disabled="isLoading" @click.prevent="handleSubmit(onSubmit)" type="filled" ycolor="success">
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>

    <vs-popup :active.sync="popupActiveDetails" title="تفاصيل المستخدم">
      <form>
        <div class="vx-row">
          <img :src="user.image_url" class="m-auto custom-image-size">
        </div>

        <hr style="height:1px;border-width:0;background-color:#BDBAC2">
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>الاسم : </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ user.first_name }}</h4>
            <br>
          </div>
        </div>

        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>الكنية : </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ user.last_name }}</h4>
            <br>
          </div>
        </div>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>الجنس : </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ user.gender }}</h4>
            <br>
          </div>
        </div>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>الإيميل : </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ user.email }}</h4>
            <br>
          </div>
        </div>
        <hr style="height:1px;border-width:0;background-color:#BDBAC2">
        <div class="vx-row mt-12">
          <div class="vx-col sm:w-1/4 mb-4">
            <vs-button @click="popupActiveDetails=false" type="filled" ycolor="success">حسناً
            </vs-button>
          </div>
        </div>
      </form>
    </vs-popup>
  </div>
</template>

<script>

  import AgTable from '../shared-components/ag-grid/AgTable'
  import AgTableBtnCell from '../shared-components/ag-grid/AgTableBtnCell.vue'
  import AgTableImageCell from '../shared-components/ag-grid/AgTableImageCell'
  import utilities from '../utilities/index'
  import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
  import vSelect from 'vue-select';
  import imageUploader from '../shared-components/ImageUploader';
  import Loader from "@/app/shared/shared-components/Loader";
  import AgButton from "@/app/shared/shared-components/ag-grid/AgButton";

  const userRepo = SharedRepositoryFactory.get('userRepository');

  export default {
    name: "DashboardUsersMain",
    components: {
      AgTable,
      vSelect,
      imageUploader,
      Loader,
    },

    data() {
      return {
        gridOptions: null,
        gridApi: null,
        rowData: [],
        user: {
          id: null,
          first_name: '',
          last_name: '',
          email: '',
          image_url: '',
          password_confirmation: '',
          gender: ''
        },
        genderOptions: [
          {
            name: 'ذكر',
            value: 'Male'
          },
          {
            name: 'أنثى',
            value: 'Female'
          }
        ],
        imagePreview: null,
        imageRequired: false,
        dialogTitle: '',
        isLoading: true,
        popupActiveDetails: false,
        popupActive: false,
      }
    },

    methods: {
      createColDefs() {
        let self = this;
        return [
          {
            headerName: 'المعرف',
            field: 'id',
            filter: 'agTextColumnFilter',
            suppressSizeToFit: true,
            width: 225,
            checkboxSelection: true,
          },
          {
            headerName: 'الصورة',
            field: 'image_url',
            cellRendererFramework: AgTableImageCell,
            cellRendererParams: {
              width: '30%'
            }
          },

          {
            headerName: 'الاسم',
            field: 'first_name',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'الكنية',
            field: 'last_name',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'الإيميل',
            field: 'email',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'الأدوار',
            cellRendererFramework: AgButton,
            field: 'id',
            filter: false,
            cellRendererParams: {
              size: 'small',
              color: 'primary',
              name: 'عرض الأدوار',
              type: 'gradient',
              click(id) {
                self.rowClickedRoles(id)
              }
            }
          },
          {
            headerName: 'الصلاحيات',
            cellRendererFramework: AgButton,
            field: 'id',
            filter: false,
            cellRendererParams: {
              size: 'small',
              color: 'primary',
              name: 'عرض الصلاحيات',
              type: 'gradient',
              click(id) {
                self.rowClickedPermissions(id)
              }
            }
          },
          {
            headerName: 'الإجراءات',
            field: 'id',
            cellRendererFramework: AgTableBtnCell,
            cellRendererParams: {
              deleteRecord: function (id, rowIndex) {
                self.deleteUser(id, rowIndex)
              },
              editRecord: function (record) {
                self.editRecord(record)
              },
              viewRecordDetails: function (record) {
                self.viewRecordDetails(record)
              },
              actions: ['view', 'edit', 'delete']
            },
          },
        ];
      },
      rowClickedRoles(id) {
        this.$router.push(`/users/${id}/roles`)
      },
      rowClickedPermissions(id) {
        this.$router.push(`/users/${id}/permissions`)
      },
      viewRecordDetails(record) {
        this.popupActiveDetails = true;
        this.user = record;
      },
      deleteUser(ids, index, isMultiple = false) {
        this.isLoading = true;
        if (isMultiple)
          ids = ids.join(',');
        userRepo.deleteUser(ids).then((response) => {
          if (response.code === 200) {
            if (isMultiple)
              this.fetchAllUsers('dashboard');
            else {
              this.rowData.splice(index, 1);
              this.rerender();
            }
          }
          this.isLoading = false;
        })
      },
      fetchAllUsers(projectName) {
        userRepo.fetchAllUsers(projectName).then((response) => {
          this.rowData = response;
          this.isLoading = false;
        });
      },
      fileUploaded(file) {
        this.user.image_url = file;
      },
      rerender() {
        this.gridApi.setRowData(this.rowData)
      },
      openPopup() {
        this.$refs.validationObserver.reset();
        this.popupActive = true;
      },
      createRecord() {
        utilities.initObjectMembers(this.user);
        this.$refs.imageUploader.clearImage();
        this.imagePreview = null;
        this.dialogTitle = 'اضافة مستخدم';
        this.openPopup();
      },
      editRecord(user) {
        this.$refs.imageUploader.clearImage();
        this.dialogTitle = 'تعديل مستخدم';
        Object.assign(this.user, user);
        this.imagePreview = this.user.image_url;
        this.openPopup();
      },
      onSubmit() {
        this.isLoading = true;
        if (typeof this.user.image_url == "string")
          delete this.user.image_url;
        if (this.user.id !== null) {
          this.updateUser(this.user);
        } else {
          this.storeUser(this.user);
        }
      },
      storeUser(user) {
        console.log(user);
        userRepo.storeUser(this.$objectToFD(user)).then((response) => {
          this.isLoading = false;
          this.rowData.unshift(response.data.data);
          this.handleResponse(response.data)
        });
      },
      handleResponse() {
        this.popupActive = false;
      },
      updateUser(user) {
        userRepo.updateUser(this.$objectToFD(user), user.id).then((response) => {
          this.isLoading = false;
          let index = this.rowData.map((m) => {
            return m.id;
          }).indexOf(response.data.data.id);
          this.rowData[index] = response.data.data;
          this.rerender();
          this.popupActive = false;
        });
      },
    },

    created() {
      this.gridOptions = {};
      this.gridOptions.columnDefs = this.createColDefs();
      const $this = this;
      this.$watch(
        () => this.$route.params,
        async () => {
          $this.fetchAllUsers('dashboard')
        },
        {immediate: true}
      );
    },

    mounted() {
      this.gridApi = this.gridOptions.api;
    }
  }
</script>
