import {extend, localize} from 'vee-validate';
import ar from './ar.json';
import * as rules from 'vee-validate/dist/rules';

export default {
    initValidations() {
        Object.keys(rules).forEach(rule => {
            extend(rule, rules[rule]);
        });
        extend('alpha', {
            ...rules.alpha,
            ...rules.alpha_spaces,
            ...rules.alpha_num,
            ...rules.length
        });
        localize({
            ar
        });
        localize('ar');
    }
}
