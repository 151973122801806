import app from '../../../main'

export default {
  loader: null,
  start() {
    this.loader = app.$loading.show()
  },
  hide() {
    this.loader && this.loader.hide();
    this.loader = null;
  },
  getOverlayOptions() {
    return {
      loader: "dots",
      color: "#4B364E",
      isFullPage: true,
      container: null,
      canCancel: true,
    }
  }
}
