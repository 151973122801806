<template>
  <div>
    <vs-card>
      <vs-row vs-type="flex" vs-justify="space-between">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <h5>
            <vs-chip color="primary">المعرف : {{ currentRole.id }}</vs-chip>
          </h5>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <h5>
            <vs-chip color="primary"> اسم الدور : {{ currentRole.name }}</vs-chip>
          </h5>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <h5>
            <vs-chip color="primary"> الاسم بالعربية : {{ currentRole.name_ar }}</vs-chip>
          </h5>
        </vs-col>
      </vs-row>
    </vs-card>
    <div class="vx-row mt-5">
      <div class="vx-col sm:w-1/2 w-full mb-2">
        <vs-button :disabled="isLoading" ycolor="success" type="filled" @click.prevent="onSubmit()">
          حفظ
        </vs-button>
      </div>
      <vx-drag-and-drop-multiple-lists v-if="permissions.length > 0" list1title="كافة الصلاحيات" list2title="الصلاحيات المراد إضافتها لهذا الدور"
                                       :list1="permissions" :list2="addedPermissions"></vx-drag-and-drop-multiple-lists>
    </div>
  </div>
</template>

<script>
  import AgTable from '../shared-components/ag-grid/AgTable'
  import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
  import vSelect from "vue-select";
  import Loader from "@/app/shared/shared-components/Loader";

  const roleRepo = SharedRepositoryFactory.get('roleRepository');
  const permissionRepo = SharedRepositoryFactory.get('permissionRepository');
  export default {
    name: "RoleMain",
    components: {
      AgTable,
      Loader,
      vSelect
    },
    data() {
      return {
        currentRole: {},
        role: {
          id: null,
          name: '',
          name_ar: '',
          desc: ''
        },
        isLoading: true,
        permissions: [],
        permission: {
          id: null,
          name: '',
          name_ar: '',
          desc: ''

        },
        addedPermissions: [],
      }
    },
    methods: {
      onSubmit() {
        const ids = this.addedPermissions.map(item => item.id);
        permissionRepo.givePermissionToRole(ids, this.$route.params.id).then(() => {
          this.fetchAllRoles();
          this.isLoading = false;
        })
      },
      fetchAllRoles() {
        roleRepo.fetchAllRoles().then((data) => {
          data.forEach(item => {
            if (item.id == this.$route.params.id) {
              this.addedPermissions = item.permissions;
              this.currentRole = item;
            }
          });
          this.fetchAllPermissions()
          this.isLoading = false;
        });
      },
      fetchAllPermissions() {
        permissionRepo.fetchAllPermissions().then((data) => {
          this.permissions = data.filter(item => !this.addedPermissions.find(permission => (permission.name === item.name)));
          });
      }
    },
    created() {
      this.fetchAllRoles();
    }
  }
</script>

<style scoped>

</style>
