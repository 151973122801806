<template>
  <vs-button
    :size="params.size"
    :color="buttonColor()"
    @click="clicked"
    type="filled"
    :disabled="isButtonDisabled()">
    {{params.name}}
  </vs-button>
</template>

<script>
  import Vue from "vue";

  export default Vue.extend({
    name: "AgButton",
    methods: {
      clicked() {
        this.params.click(this.params.value)
      },
      isButtonDisabled() {
        if (this.params.value.hasOwnProperty("data")) {
          if (this.params.value.data.status === 'Rejected' || this.params.value.data.status === 'Delivered')
            return true;
          else if (this.params.value.colDef.headerName === 'رفض الطلب' && this.params.value.data.status !== 'Review' || this.params.value.colDef.headerName === 'رفض المنتج المطلوب' && this.params.value.data.status !== 'Review')
            return true;
          else if (this.params.value.colDef.headerName === 'تغيير حالة المنتج' && this.params.value.data.status === 'Ready')
            return true;
          else return false;
        }
        else return false;
      },
      buttonColor(){
        if (this.params.value.hasOwnProperty("data")) {
          switch (this.params.value.data.status) {
            case 'Review':
              return "#EAB848";
            case 'Approved':
              return "#5F7A99";
            case 'UnderProcessing':
              return "#FE948E";
            case 'Ready' :
              return "#8158A4";
            case 'UnderDelivery' :
              return "#6495ED";
            case 'Delivered' :
              return "#9ECC3B";
            case 'Rejected' :
              return "#EF4141";
          }
        }
        else return "primary";
        },
      }
  })
</script>
