<template>
  <div>
    <vs-card>
      <vs-row vs-type="flex" vs-justify="space-between">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <h5>
            <vs-chip color="primary">المعرف : {{ product.id }}</vs-chip>
          </h5>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <h5>
            <vs-chip color="primary"> اسم المنتج : {{ product.name }}</vs-chip>
          </h5>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <h5>
            <vs-chip color="primary"> الصنف : {{ product.category.name }}</vs-chip>
          </h5>
        </vs-col>
      </vs-row>
    </vs-card>
    <ag-table
            :gridOptions="gridOptions"
            :rowData="subProducts"
            :rtl=false
            @emitAddNewRecord="createRecord()"/>

    <store-popup
            :subProducts="manySubProducts"
            :product-id="$route.params.id"
            @created="subProductCreated"
            ref="storePopupRef"
    />
    <update-popup
            :subProduct="subProduct"
            :product-id="$route.params.id"
            @updated="subProductUpdated"
            ref="updatePopupRef"
    />
  </div>
</template>

<script>
  import StorePopup from "./StorePopup";
  import UpdatePopup from "./UpdatePopup";
  import {RepositoryFactory} from '../../repositories/ecommerceRepositoryFactory'
  import AgTable from '../../../shared/shared-components/ag-grid/AgTable.vue'
  import AgTableBtnCell from "../../../shared/shared-components/ag-grid/AgTableBtnCell";
  import AgTableImageCell from "../../../shared/shared-components/ag-grid/AgTableImageCell";
  import AgButton from "../../../shared/shared-components/ag-grid/AgButton";


  const productRepository = RepositoryFactory.get("product");
  const subProductRepository = RepositoryFactory.get('subProduct');
  export default {
    name: "SubProduct",
    data() {
      return {
        product: {
          category: {}
        },
        duplicateSubProductId :'',
        subProducts: [],
        gridOptions: null,
        isLoading: false,
        subProduct: {
          name: null,
          price: null,
          desc: null,
          allow_upload_image: false,
            details: [
              {
                key: null,
                value: null
              }
            ],
        },
        manySubProducts:[],
      }
    },
    components: {
      AgTable,
      StorePopup,
      UpdatePopup
    },
    methods: {
      createRecord() {
        this.init();
        this.$refs.storePopupRef.open();
      },
      init() {
        this.subProduct = {
          name: null,
          price: null,
          qty: null,
          desc: null,
          allow_upload_image: false,
          hidden:false,
          details: [
            {
              key: null,
              value: null
            }
          ],
          package_size_id: null,
          tags: [],
          variants:[],
          images:[]
        };
        this.manySubProducts =[];
        this.manySubProducts.push(this.subProduct);
      },
      editRecord(subProduct) {
        if (this.subProduct.sub_product_details == null)
          this.subProduct.sub_product_details = [];
        this.subProduct = subProduct;
        this.$refs.updatePopupRef.open();
      },
      subProductCreated(subProducts) {
        subProducts.forEach(item => {
          this.subProducts.push(item)
        });
      },

      subProductUpdated(subProduct) {
        let index = this.subProducts.findIndex(p => p.id === subProduct.id);
        this.subProducts[index] = subProduct;
        this.gridApi.setRowData(this.subProducts)
      },
      viewSubProductDetails(subProduct) {
        this.$router.push(`/ecommerce/products/${this.$route.params.id}/sub_products/${subProduct.id}`)
      },
      getAll() {
        productRepository.get(this.$route.params.id).then((response) => {
          this.product = response.data;
          this.isLoading = false;
        });
        productRepository.getSubProducts(this.$route.params.id).then(subProducts => {
          this.subProducts = subProducts.data;
          this.isLoading = false;
        })
      },
      duplicate(){
        subProductRepository.duplicateSubProduct(this.duplicateSubProductId).then((response) => {
          this.subProducts.unshift(response.data);
          this.isLoading = false;
        });
      },
      confirmDuplicate(id) {
        this.duplicateSubProductId = id,
                this.$vs.dialog({
                  type: 'confirm',
                  color: 'danger',
                  title: 'هل أنت متأكد من تكرار المنتج ؟',
                  text: `في حال الموافقة, لن تتمكن من التراجع!`,
                  acceptText: 'تكرار',
                  accept: this.duplicate,
                });
      },
      /*hiddenSubProduct(id){
        subProductRepository.hiddenSubProduct(id).then((subProduct) => {
          let index = this.subProducts.findIndex(p => p.id === subProduct.id);
          this.subProducts[index] = subProduct;
          this.gridApi.setRowData(this.subProducts)
          this.isLoading = false;
        });
      },*/
      createColDefs() {
        let self = this;
        return [
          {
            headerName: 'المعرف',
            field: 'id',
            filter: 'agTextColumnFilter',
            suppressSizeToFit: true,
            width: 225,
            headerCheckboxSelection: true,
            checkboxSelection: true,
          },
          {
            headerName: 'الصورة',
            valueGetter: window.primaryImageGetter,
            cellRendererFramework: AgTableImageCell
          },
          {
            headerName: 'الاسم',
            field: 'name',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'السعر',
            field: 'price',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'الكمية',
            field: 'qty',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'الوصف',
            field: 'desc',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'حجم التغليف',
            field: 'package_size.size',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'تكرار المنتج الفرعي',
            cellRendererFramework: AgButton,
            field: 'id',
            filter: false,
            cellRendererParams: {
              size: 'small',
              color: 'primary',
              name: 'تكرار المنتج الفرعي',
              click(id) {
                self.confirmDuplicate(id)
              }
            }
          },
        /*  {
            headerName: 'عرض أو إخفاءالمنتج',
            cellRendererFramework: AgButton,
            field: 'hidden',
            valueGetter:
                    function getObject(params) {
                      return params
                    },
            filter: false,
            cellRendererParams : params => {
              return {
                size: 'small',
                color: 'primary',
                name: `${params.value.data.hidden ? 'عرض' : 'إخفاء'}`,
                click(valueGetter) {
                  self.hiddenSubProduct(valueGetter.data.id)
                }
              }
            }
          },*/
          {
            headerName: 'الإجراءات',
            field: 'id',
            cellRendererFramework: AgTableBtnCell,
            cellRendererParams: {
              editRecord: function (subProduct) {
                self.editRecord(subProduct)
              },
              viewRecordDetails: function (subProduct) {
                self.viewSubProductDetails(subProduct)
              },
              actions: ['view', 'edit']
            },
          },
        ];
      },
    },
    created() {
      this.gridOptions = {};
      this.getAll();
      this.gridOptions.columnDefs = this.createColDefs();
    },
    mounted() {
      this.gridApi = this.gridOptions.api;
    }
  }
  window.primaryImageGetter = function abValueGetter(params) {
    if (params.data.sub_product_images.length > 0) {
      let image = params.data.sub_product_images.find(image => image.is_primary);
      return (image !== undefined) ? image.image_url : params.data.sub_product_images[0].image_url
    }
  };
</script>
