import {httpClient} from "../shared-services/httpClient";
import {environment} from "@/environment";

const URL = environment.apiBaseURL + 'roles';
export default {

  fetchAllRoles() {
    return httpClient.get(URL)
      .then(res => res.data.data)
      .catch(error => console.log(error))
  },
  storeRole(role) {
    return httpClient.post(URL, role)
      .then(res => res)
      .catch(error => console.log(error))
  },
  updateRole(role, id) {
    const url = URL + `/${id}`;
    role.append('_method', 'PUT');
    return httpClient.post(url, role)
      .then(res => res)
      .catch(error => console.log(error))
  },
  deleteRole(roleId) {
    const url = URL + `/${roleId}`;
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error))
  },
  assignRoleToUser(rolesIds, userId) {
    const url = environment.apiBaseURL + `users/${userId}` + `/roles`
    let rolesIdsArray = {roles: rolesIds};
    return httpClient.post(url, rolesIdsArray)
      .then(res => res.data.data)
      .catch((error => console.log(error)))
  },
  removeRoleFromUser(roleId, userId) {
    const url = environment.apiBaseURL + `users/${userId}` + `/roles/${roleId}`
    return httpClient.delete(url)
      .then(res => res["data"])
      .catch(error => console.log(error))
  }
}
