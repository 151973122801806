<template>
    <vs-row vs-type="flex" vs-justify="space-between" dir="rtl">
        <vs-col vs-w="3">
    <feather-icon v-if="!isIconShow()"  @click="clicked" :icon="params.icon"  :style="{color : iconColor()}" :svgClasses="['h-8 w-8 cursor-pointer']" class="mb-2" />
    <h3 v-else :style="{color : iconColor()}" >-</h3>
        </vs-col>
            <div class="verticalLine"></div>
        <vs-col vs-w="3">
    <feather-icon v-if="!isSecondIconShow()"  @click="clickedSecondIcon" :icon="params.secondIcon"  :style="{color : iconColor()}" :svgClasses="['h-8 w-8 cursor-pointer']" />
    <h3 v-else :style="{color : iconColor()}">-</h3>
        </vs-col>
            <div v-if="params.thirdIcon" class="verticalLine"></div>
        <vs-col vs-w="3">
    <feather-icon  @click="clickedThirdIcon" :icon="params.thirdIcon"  :style="{color : iconColor()}" :svgClasses="['h-8 w-8 cursor-pointer']" />
        </vs-col>
    </vs-row>
</template>

<script>
    import Vue from "vue";
    import utilities from "@/app/shared/utilities";
    export default Vue.extend({
        name: "AgIcon",
            methods: {
                clicked() {
                    this.params.click(this.params.value)
                },
                clickedSecondIcon(){
                    this.params.clickSecondIcon(this.params.value)
                },
                clickedThirdIcon() {
                    this.params.clickThirdIcon(this.params.value)
                },
                iconColor(){
                    return utilities.setOrderStatusColor(this.params.value.data.status);
                },
            isSecondIconShow() {
                const roles = JSON.parse(localStorage.getItem('user')).user.roles.map((role) => role.name);
                        if (this.params.value.data.status === 'Rejected' || this.params.value.data.status === 'Delivered')
                            return true;
                        else if (this.params.value.data.status !== 'Review')
                            return true;
                        else if ((this.params.value.colDef.headerName === 'خيارات الطلب')&&(roles.includes('super_admin'))||(this.params.value.colDef.headerName === 'خيارات الطلب')&&(roles.includes('store_admin')))
                            return true;
                        else if ((this.params.value.colDef.headerName === 'خيارات المنتج المطلوب')&&(roles.includes('supervisor'))||(this.params.value.colDef.headerName === 'خيارات المنتج المطلوب')&&(roles.includes('super_admin')))
                            return true;
                        else return false;
                },
                isIconShow(){
                    const roles = JSON.parse(localStorage.getItem('user')).user.roles.map((role) => role.name);
                        if (this.params.value.data.status === 'Rejected' || this.params.value.data.status === 'Delivered')
                            return true;
                        else if ((roles.includes('store_admin'))&& (this.params.value.data.status === 'Ready'))
                            return true;
                        else if ((this.params.value.colDef.headerName === 'خيارات الطلب')&&(roles.includes('store_admin')) || (this.params.value.colDef.headerName === 'خيارات الطلب')&&(roles.includes('super_admin')) )
                            return true;
                        else if ((this.params.value.colDef.headerName === 'خيارات المنتج المطلوب')&&(roles.includes('supervisor'))||(this.params.value.colDef.headerName === 'خيارات المنتج المطلوب')&&(roles.includes('super_admin')))
                            return true;
                        else return false;
                    },
    }
    })
</script>

<style scoped>
    .verticalLine {
        border-left: 1px solid black;

    }
     h3 {
         text-align: center;
     }
</style>