import moment from 'moment';

export default {
    formatDate(date, format) {
        return moment(date).format(format)
    },
    initObjectMembers(object) {
        Object.keys(object).forEach((key) => {
            object[key] = null
        });
    },
    transformToFormData(obj) {
        const formData = new FormData();
        for (const [key, value] of Object.entries(obj)) {
            if (value != null) {
                formData.append(`${key}`, `${value}`);
            }
        }
        if (obj.image_url) {
            formData.delete('image_url');
            formData.append('image_url', obj.image_url);
        }
        if (obj.image_preview_url) {
            formData.delete('image_preview_url');
            formData.append('image_preview_url', obj.image_preview_url);
        }
        return formData;
    },
    elapsedTime(startTime) {
        let x = new Date(startTime);
        let now = new Date();
        var timeDiff = now - x;
        timeDiff /= 1000;

        var seconds = Math.round(timeDiff);
        timeDiff = Math.floor(timeDiff / 60);

        var minutes = Math.round(timeDiff % 60);
        timeDiff = Math.floor(timeDiff / 60);

        var hours = Math.round(timeDiff % 24);
        timeDiff = Math.floor(timeDiff / 24);

        var days = Math.round(timeDiff % 365);
        timeDiff = Math.floor(timeDiff / 365);

        var years = timeDiff;

        if (years > 0) {
            return 'منذ ' + years + (years > 1 ? ' سنوات ' : ' سنة ')
        } else if (days > 0) {
            return 'منذ ' + days + (days > 1 ? ' أيام ' : ' يوم ')
        } else if (hours > 0) {
            return 'منذ ' + hours + (hours > 1 ? ' ساعات ' : ' ساعة ')
        } else if (minutes > 0) {
            return 'منذ ' + minutes + (minutes > 1 ? ' دقائق ' : ' دقيقة')
        } else if (seconds > 0) {
            return (seconds > 1 ? 'منذ ' + seconds + ' ثانية' : 'الآن')

        }
    },
    fetchRecordIndex(dataLength, index, action) {
        let record = {index: index};
        if (action == 'next')
            record.index++;
        else if (action == 'previous')
            record.index--;
        record.isPrevious = record.index <= 0 ? false : true;
        record.isNext = record.index < dataLength - 1 ? true : false;
        return record
    },
    changeOrderStatus(status, isRejected) {
        let statusObject = {
            statusAction: '', newStatus: '', fromStatus: '',
            toStatus: '', fromStatusColor: '', toStatusColor: '',
        };
        if (!isRejected) {
            switch (status) {
                case 'Review': {
                    statusObject.statusAction = 'قبول الطلب';
                    statusObject.newStatus = 'Approved';
                    statusObject.fromStatus = 'قيدالمراجعة';
                    statusObject.toStatus = 'مقبول';
                    statusObject.fromStatusColor = '#FE948E';
                    statusObject.toStatusColor = '#5F7A99';
                    break;
                }
                case 'Approved': {
                    statusObject.fromStatus = 'مقبول';
                    statusObject.fromStatusColor = '#5F7A99';
                    statusObject.statusAction = ' تجهيز الطلب';
                    statusObject.newStatus = 'Ready';
                    statusObject.toStatus = 'جاهز';
                    statusObject.toStatusColor = '#8158A4';
                    break;
                }
                case 'Ready' : {
                    statusObject.statusAction = 'جار توصيل الطلب';
                    statusObject.newStatus = 'UnderDelivery';
                    statusObject.fromStatus = 'جاهز';
                    statusObject.toStatus = 'قيد التوصيل';
                    statusObject.fromStatusColor = '#8158A4';
                    statusObject.toStatusColor = '#6495ED';
                    break;
                }
                case 'UnderDelivery' : {
                    statusObject.statusAction = 'توصيل الطلب';
                    statusObject.newStatus = 'Delivered';
                    statusObject.fromStatus = 'قيد التوصيل';
                    statusObject.toStatus = 'تم التوصيل';
                    statusObject.fromStatusColor = '#6495ED';
                    statusObject.toStatusColor = '#9ECC3B';
                    break;
                }
            }
        } else if (status === 'Review') {
            statusObject.newStatus = 'Rejected';
            statusObject.statusAction = 'رفض الطلب';
            statusObject.fromStatus = 'قيد المراجعة';
            statusObject.toStatus = 'مرفوض';
            statusObject.fromStatusColor = '#FE948E';
            statusObject.toStatusColor = '#EF4141';
        }
        return statusObject;
    },
    setOrderStatusColor(status) {
        switch (status) {
            case 'Review':
                return '#FE948E';
            case 'Approved':
                return '#5F7A99';
            case 'Ready' :
                return '#8158A4';
            case 'UnderDelivery':
                return '#6495ED';
            case 'Delivered':
                return '#9ECC3B';
            case 'Rejected':
                return '#EF4141';
        }
    },
    getFilterModel(params) {
        let filterValues, filterKeys, filterModel;
        let array = [];
        if (Object.keys(params).length !== 0) {
            filterKeys = Object.keys(params);
            Object.values(params).forEach(item => {
                filterValues = (({filter}) => ({filter}))(item);
                array.push(item.filter)
            });
            filterModel = '';
            filterValues = array;
            filterKeys.map((item, index) => {
                filterValues.map((i, j) => {
                    if (index === j)
                        filterModel += ('&filter[' + item + ']=' + i)
                })
            })
        }
        return filterModel;
    },
    getSortModel(params) {
        let sortModel;
        if (Object.keys(params).length !== 0) {
            sortModel = '';
            params.map((item) => {
                sortModel += ('&sort[' + item.colId + ']=' + item.sort)
            });
        }
        return sortModel;
    },
    getQueryParameters(params, perPage, page) {
        let filter = this.getFilterModel(params.request.filterModel);
        let sort = this.getSortModel(params.request.sortModel);
        if (filter === undefined)
            filter = '';
        if (sort === undefined)
            sort = '';
        return `?perPage=${perPage}` + `&page=${page}` + filter + sort;
    },
    isAdmin() {
        const roles = JSON.parse(localStorage.getItem('user')).user.roles.map((role) => role.name);
        if (roles.includes('super_admin'))
            return true;
    },
}