import Home from "./views/Home"
import Product from "./views/products/Product";
import Market from "./views/Markets/Market";
import Store from "./views/Stores/Store.vue";
import StoreAdmins from "./views/Stores/StoreAdmins.vue";
import Category from "./views/Categories/Category";
import variantKey from "./views/variant/variantKey";
import VariantValue from "./views/varientValue/VariantValue";
import SubProduct from "./views/SubProducts/SubProduct";
import PersonalOrder from "./views/Orders/PersonalOrder";
import OrphanGiftOrder from "./views/Orders/OrphanGiftOrder";
import FriendGiftOrder from "./views/Orders/FriendGiftOrder";
import OrderDetail from "./views/Orders/OrderDetail";
import SubProductDetails from "./views/SubProducts/SubProductDetails";
import PackageSizes from "./views/Packaging/PackageSize";
import PackageClasses from "./views/Packaging/PackageClass";

const ecommerceRouts = [
  {
    path: '/ecommerce',
    component: () => import('../../layouts/main/Main.vue'),
    children: [
      {
        path: '',
        component: Home,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
          ],
          pageTitle: 'الصفحة الرئيسية'
        }
      },
      {
        path: '/ecommerce/market',
        component: Market,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'المتاجر', active: true}
          ],
          pageTitle: 'المتاجر'
        }
      },
      {
        path: '/ecommerce/products',
        component: Product,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'المنتجات', active: true}
          ],
          pageTitle: 'المنتجات'
        }
      },
      {
        path: '/ecommerce/stores',
        component: Store,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'المحال', active: true}
          ],
          pageTitle: 'المحال'
        }
      },
      {
        path: '/ecommerce/storeAdmins/:id',
        component: StoreAdmins,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'المحال', url:'/ecommerce/stores'},
            {title: 'مدراء المحل', active: true}
          ],
          pageTitle: 'مدراء المحل'
        }
      },
      {
            path: '/ecommerce/personalOrders',
            component: PersonalOrder,
            meta: {
              breadcrumb: [
                {title: 'Home', url: '/ecommerce'},
                {title: ' شخصي', active: true}
              ],
              pageTitle: 'الطلبات'
            }
          },
          {
            path: '/ecommerce/friendGiftOrders',
            component: FriendGiftOrder,
            meta: {
              breadcrumb: [
                {title: 'Home', url: '/ecommerce'},
                {title: 'هدية لصديق', active: true}
              ],
              pageTitle: 'الطلبات'
            }
          },
          {
            path: '/ecommerce/orphanGiftOrders',
            component: OrphanGiftOrder,
            meta: {
              breadcrumb: [
                {title: 'Home', url: '/ecommerce'},
                {title: 'هدية ليتيم', active: true}
              ],
              pageTitle: 'الطلبات'
            }
          },
      {
        path: '/ecommerce/orders/:id/order_details',
        component: OrderDetail,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'الطلبات', url: '/ecommerce/orders'},
            {title: 'تفاصيل الطلب', active: true}
          ],
          pageTitle: 'تفاصيل الطلب'
        }
      },
      {
        path: '/ecommerce/categories',
        component: Category,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'اصناف المنتجات', active: true}
          ],
          pageTitle: 'اصناف المنتجات'
        }
      },
      {
        path: '/ecommerce/variant',
        component: variantKey,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'الخصائص', active: true}
          ],
          pageTitle: 'الخصائص'
        }
      },
      {
        path: '/ecommerce/products/:id/sub_products',
        name: 'sub_products',
        component: SubProduct,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
            {title: 'المنتجات', url: '/ecommerce/products'},
            {title: 'المنتجات الفرعية', active: true}

          ],
          pageTitle: 'المنتجات الفرعية'
        }
      },
      {
        path: '/ecommerce/products/:id/sub_products/:subProductId/',
        component: SubProductDetails,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/ecommerce'},
          ],
          pageTitle: 'تفاصيل المنتج الفرعي'
        },
        children: [
          {
            name:'subProductImages',
            path: 'images',
            component: () => import('./views/SubProducts/SubProductImage'),
            meta: {
              breadcrumb: [
                {title: 'Home', url: '/ecommerce'},
                {title: 'المنتجات', url: '/ecommerce/products'},
                {title: 'المنتجات الفرعية', name:'sub_products'},
                {title: 'صور المنتج الفرعي', active: true}

              ],
              pageTitle: ' صور المنتج الفرعي'
            }
          },
          {
            name:'subProductTags',
            path: 'tags',
            component: () => import('./views/SubProducts/SubProductTag'),
            meta: {
              breadcrumb: [
                {title: 'Home', url: '/ecommerce'},
                {title: 'المنتجات', url: '/ecommerce/products'},
                {title: 'المنتجات الفرعية', name:'sub_products'},
                {title: 'تاغات المنتج الفرعي', active: true}

              ],
              pageTitle: 'تاغات المنتج الفرعي'
            }
          },
          {
            name:'subProductFeedbacks',
            path: 'feedbacks',
            component: () => import('./views/SubProducts/SubProductFeedback/SubProductFeedback'),
            meta: {
              breadcrumb: [
                {title: 'Home', url: '/ecommerce'},
                {title: 'المنتجات', url: '/ecommerce/products'},
                {title: 'المنتجات الفرعية', name:'sub_products'},
                {title: 'مراجعات المنتج الفرعي', active: true}

              ],
              pageTitle: 'مراجعات المنتج الفرعي'
            }
          },
          {
            name:'subProductVariants',
            path: 'variants',
            component: () => import('./views/SubProducts/SubProductVariant'),
            meta: {
              breadcrumb: [
                {title: 'Home', url: '/ecommerce'},
                {title: 'المنتجات', url: '/ecommerce/products'},
                {title: 'المنتجات الفرعية', name:'sub_products'},
                {title: 'خصائص المنتج الفرعي', active: true}

              ],
              pageTitle: 'خصائص المنتج الفرعي'
            }
          }
        ]
      },
      {
        path: '/ecommerce/variant/:id/variant_value',
        component: VariantValue,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'الخصائص', url :'/ecommerce/variant'},
            {title: 'قيم الخصائص', active: true}
          ],
          pageTitle: 'قيم الخصائص'
        }
      },
      {
        path: '/ecommerce/packageSizes',
        component: PackageSizes,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'حجوم التغليف', active: true}
          ],
          pageTitle: 'حجوم التغليف'
        }
      },
      {
        path: '/ecommerce/packageSize/:id/packageClasses',
        component: PackageClasses,
        meta: {
          breadcrumb: [
            {title: 'Home', url: '/'},
            {title: 'حجوم التغليف', url: '/ecommerce/packageSizes'},
            {title: 'فئات حجم التغليف', active: true}
          ],
          pageTitle: 'فئات حجم التغليف'
        }
      }
    ]
  },

];
export default ecommerceRouts
